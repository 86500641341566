myApp.controller( 'adminUsersController', [
                        '$scope',
                        'userModel',
                        '$location',
                        '$routeParams',
                        '$anchorScroll',
                        'Upload',
                        function(
                                $scope, 
                                userModel,
                                $location,
                                $routeParams,
                                $anchorScroll,
                                Upload){
    $scope.user  = {};
    
    $scope.users = null;
    $scope.filters = {};
    $scope.errors = {};    
    $scope.maxSize = 9;//defaultValue                
    $scope.lastPage = null;
    $scope.pageTotal = null;
    $scope.resendEmailMessage = {};    
    $scope.statuses = [{label:'Todos',id: 2 }, {label:'Activos', id : 1 },{label:'Inactivos', id : 0} ];
    $scope.status = 2;

    angular.extend($scope,userModel,$location, $routeParams,$anchorScroll,{
        init : function(){
            $scope.filters = {
                limit : 9                
            };

            $scope.getUsers();
        },
        toggleStatus : function(user){
            console.log(user);
            //activate or deactivate the user
            userModel.toggleStatus(user.id,user.switch).then(function succesCallback(response){
                if(response.status == 200){
                    //update the label
                }
            });
        },
        initEdit : function(){
            var id = $routeParams.user_id;
            userModel.get(id).then(function successCallback(response){
                if ( response.status == 200 ){
                    $scope.user  = response.data.data;
                    $scope.user.profile_image_url = baseUrl + "/images/profile/"+$scope.user.id+"/"+$scope.user.profile_image;
                }

                if(response.status == 204){
                    $scope.user = {};
                    $scope.messages  = { not_found : true };
                }

            },function errorCallback(response){
                if(response.status == 412){
                    $scope.messages  = { invalid_action : true };
                }
            });
        },
        update : function(){
            var user = $scope.user;
            userModel.update(user).then(function successCallback(response){
                if( response.status == 200 ){
                    $scope.messageResponse = {success: true};
                    $scope.accountMessages = {success:false};
                }
                if( response.status == 204 ){
                    $scope.messageResponse = {whatError: true};
                }
            }, function successCallback(response){
                if(response.status == 412){
                    $scope.messageResponse = {validationErrors: true};    
                }
            });
        },
        submitAvatar: function () {
            
            if ($scope.form.file.$valid && $scope.file) {
                $scope.upload($scope.file);
            }
        },
        upload: function (file) {
            
            Upload.upload({
             url: baseUrl + '/images/profile',
                data: { image: file }
             }).then(function (resp) {
                 if(resp.status == 200){          
                    $scope.accountMessages = {success:true};                        
                 }
                
             }, function (resp) {
                console.log('Error status: ' + resp.status);
             }, function (evt) {
                var progressPercentage = parseInt(100.0 * evt.loaded / evt.total);
                console.log('progress: ' + progressPercentage + '%');
             });
        },
        getUsersPage : function(){            
            console.log($scope.currentPage);
            $scope.filters.page = $scope.currentPage;
            $scope.getUsers();
        },
        getUsers : function(){

            userModel.getAdminUsers($scope.filters).then(function succesCallback(response){
                if(response.status == 200){
                   $scope.users = response.data.data.data;    
                   $scope.lastPage = response.data.data.last_page;
                   $scope.pageTotal = response.data.data.total;
                }
            });
        },
        filter(){
            $scope.currentPage = 1;
            $scope.filters.page = 1;
            $scope.getUsers();
        },
        resendUserConfirmationEmail : function(id){
            $scope.resendEmailMessage  = {sending : true};
            userModel.resendUserConfirmationEmail(id).then(function successCallback(response){
                if(response.status == 200){
                    $scope.resendEmailMessage  = {sending : false};
                    $scope.resendEmailMessage = {success:true};                    
                }
            }, function errorCallback(response){

            });
        }
    });

}]);