myApp.controller('paypalPaymentsController', ['$routeParams', '$http', '$scope', '$location', 'checkoutModel', 'cartModel',
    'locationModel', 'shippingAddressModel', 'userModel', '$auth', 'ordersModel', '$timeout',
    function($routeParams, $http, $scope, $location,
        checkoutModel,
        cartModel,
        locationModel,
        shippingAddressModel,
        userModel,
        $auth,
        ordersModel,
        $timeout) {

        $scope.authorized = false;
        $scope.needToLogin = false;
        $scope.processingOrder = false;
        $scope.cart_info = {};
        $scope.total_amount = null;
        $scope.payment_success = false;
        $scope.already_paid = false;

        $scope.env = 'sandbox', // Or 'sandbox'
        $scope.client = {
            sandbox: 'AURs8_fw4V4TOqX0PgRPRrt6e-SP2ZWCN25Axf5WSVadOVBlg8CIXVN2Z6OT6DQjZ1Ix_AONV-z_2WwG', // from https://developer.paypal.com/developer/applications/
            production: 'xxxxxxxxx' // from https://developer.paypal.com/developer/applications/
        };

        $scope.payment = function(data, actions) {
            return actions.payment.create({
                payment: {
                    transactions: [{
                        amount: {
                            total: $scope.total_amount,
                            currency: 'MXN'
                        }
                    }]
                }
            });
        };


        $scope.initPaypalPayment = function(){

            userModel.getUser().then(function(response) {

                $scope.user = response.data.data;
                $scope.needToLogin = false;

                var vb_order_id = $routeParams.vb_order_id;
                $scope.vb_order_id = vb_order_id;

                ordersModel.getOrderInfo(vb_order_id).then(function(response) {

                    if (response.status == 200) {
                        var order = response.data.data;
                        $scope.total_amount = response.data.data.total_amount;

                    } else if (response.status == 202) {
                        $scope.already_paid = true;
                        console.log('Already payed');
                    }

                }, function(response) {
                    console.log("VB ORDER ERROR");
                    //order not found
                });
            }, function(response) {
                $scope.needToLogin = true;
                $location.path('/cart');
            });
        };


        $scope.onAuthorize = function(data, actions) {

            return actions.payment.execute().then(function(payment) {

                console.log("Payment processed", payment);

                ordersModel.confirmPayment($scope.vb_order_id, payment).then(function(response) {

                    if (response.status == 200) {

                        if (response.data.data.status == 'PC') {
                            $scope.payment_success = true;

                             //ok response so we clean the cart and the order info
                             cartModel.cleanCart();
                             checkoutModel.deleteCheckoutAddress();
                             checkoutModel.deleteShippingMethod();
                             checkoutModel.deletePayMethod();
 
                             var tmpcart = cartModel.getTmpCartList();
                             if (tmpcart.length > 0) {
                                 cartModel.setCartList(tmpcart);
                                 cartModel.cleanTmpCartList();
                             }

                            $scope.email = response.data.data.details.email;
                            $scope.sa = response.data.data.details.shipping_address;
                            $scope.products = response.data.data.details.order;
                        } else if (response.data.data.status == 'PCP') {
                            console.log('Has been already payed');
                        }

                    }

                }, function(response) {
                    //paypal was accepted but the order was not processed

                });
                // The payment is complete!
                // You can now show a confirmation message to the customer
            });
        };

        $scope.onReturn = function(args) {
            $scope.afterPurchase = true;
            $scope.afterPurchaseMsg = 'The item was successfully purchased.\n' + 'token: ' + args.token + ', payerId: ' + args.payerId;
        };

        $scope.onCancel = function(args) {
            $scope.afterPurchase = true;
            $scope.afterPurchaseMsg = 'The purchase was canceled.\n' + 'token: ' + args.token;
        };

        $scope.cancelOrder = function(args) {

        };
    }

]);
