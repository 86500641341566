myApp.controller( 'recoverPasswordController', [
    '$scope',
    '$location',
    'recoverpasswordModel','$routeParams','$timeout', function($scope,$location,recoverpasswordModel,$routeParams,$timeout){

    $scope.reset = {};
    $scope.recover;

    angular.extend($scope,$location,recoverpasswordModel,$routeParams,$timeout,{

    	initResetPassword : function(){
    		$scope.reset.token = $routeParams.token;
    		/*recoverpasswordModel.getpasswordinfo(function successCallback(response){
    			if(response.status == 200){

    			}
    		},function errorCallback(response){
    			//fucking error,so then, fucking redirect
    		});*/
    	},
    	resetPassword : function(){
    		$scope.sending = true;
    		$scope.errors = {};
    		if($scope.reset.password != $scope.reset.password_confirmation ){
				$scope.reset.errors  = { confirm : true };
				$scope.sending = false;
				return;
			}

			var data = {
				token : $scope.reset.token,
				password : $scope.reset.password,
				password_confirmation : $scope.reset.password_confirmation,
			};

    		recoverpasswordModel.reset(data).then(function successCallback(response){
    			$scope.sending = false;
    			if(response.status == 200){
                    $scope.reset.errors = { success : true };
                    $timeout(function(){
                        $location.path('/login');
                    },3000);
                    
    			}
    		},function errorCallback(response){
    			$scope.sending = false;
                $scope.reset.erros = {erros : true };

    		});
    	}
    });

}]);