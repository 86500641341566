myApp.controller('checkoutController', ['$routeParams', '$http', '$scope', '$location', 'checkoutModel', 'cartModel',
    'locationModel', 'shippingAddressModel', 'userModel', '$auth', 'ordersModel', '$timeout',
    function($routeParams, $http, $scope, $location,
        checkoutModel,
        cartModel,
        locationModel,
        shippingAddressModel,
        userModel,
        $auth,
        ordersModel,
        $timeout) {

        $scope.paymethods = {
            direct_transfer : {  ES : 'Deposito Oxxo, Banamex o HSBC' },
            oxxo_pay : { ES : 'OXXO Pay'  },
            paypal  : { ES : 'PayPal' },
            conekta_credit_card : { ES : 'Tarjeta de Credito'},
        };
        $scope.authorized = false;
        $scope.needToLogin = false;
        $scope.processingOrder = false;
        $scope.cart_info = {};
        $scope.total_amount = null;
        $scope.payment_success = false;
        $scope.already_paid = false;
        $scope.shippingaddresMessages = {};
        $scope.shippingStoresList = [];
        $scope.shipping_method = {};
        $scope.shippingMethodErrors = {};
        $scope.paymethodsErrors = {};
        $scope.check = {};
        $scope.edit_sa = false;
        $scope.cardListTotal = 0

        $scope.loadUserShippingMethod = function() {
            Object.values($scope.shipping_method).forEach(function(a, b) {
                var check_key = a.store.id + "_" + a.method.id;
                $scope.check[check_key] = true;
            });
        };

        $scope.clickMethod = function(method, store) {

            $scope.check = {};

            //generate the key
            var key = "_" + store.name.toLowerCase().replace(/\s/g, '_') + "_";

            if (!$scope.shipping_method.hasOwnProperty(key)) {
                $scope.shipping_method[key] = {
                    store: store,
                    method: method
                };
            } else {
                $scope.shipping_method[key].method = method;
            }

            $scope.loadUserShippingMethod();
        };

        $scope.buildStoresList = function(list) {

            var cartList = null;
            var storesLists = [];
            var tmpStoreList = [];
            if (list !== undefined) {
                cartList = list;

                cartList.map(function(a, b) {
                    if (tmpStoreList.indexOf(parseInt(a.store_id)) == -1) {
                        var store = {};
                        store.id = parseInt(a.store_id);
                        store.name = a.store_name;
                        store.country_id = a.store_country_id;
                        store.state_id = a.store_state_id;
                        store.city_id = a.store_city_id;
                        tmpStoreList.push(parseInt(a.store_id));
                        storesLists.push(store);
                    }
    
                });
    
                return storesLists;
            } else {
                cartModel.getCartList().then(
                    response => {
                        console.log("response",response);
                        if(response.data){
                            cartList = response.data
                            cartList.map(function(a, b) {
                                if (tmpStoreList.indexOf(parseInt(a.store_id)) == -1) {
                                    var store = {};
                                    store.id = parseInt(a.store_id);
                                    store.name = a.store_name;
                                    store.country_id = a.store_country_id;
                                    store.state_id = a.store_state_id;
                                    store.city_id = a.store_city_id;
                                    tmpStoreList.push(parseInt(a.store_id));
                                    storesLists.push(store);
                                }
                
                            });
                
                            return storesLists;
                        }
                    }
                )
            }
        };

        $scope.getCartListTotal = function (){
            var list =  [];
            var sum = 0;
            /*cartModel.getCartList().then(
                response => {
                    list = response.data
                    list.map(function(item) {
                        sum+=item.price;
                    });
                    return sum;
                },
                error => {
                    list.map(function(item) {
                        sum+=item.price;
                    });
                    return sum;
                }
            )*/
            return sum
        }

        $scope.buildStoreShippingList = function() {
            var cartList = null;
            var storesLists = [];
            var tmpStoreList = [];
            cartModel.getCartList().then(
                response => {
                    if(response.data){
                        cartList = response.data
                        cartList.map(function(a, b) {
                            if (tmpStoreList.indexOf(parseInt(a.store_id)) == -1) {
                                var store = {};
                                store.id = parseInt(a.store_id);
                                store.name = a.store_name;
                                store.country_id = a.store_country_id;
                                store.state_id = a.store_state_id;
                                store.city_id = a.store_city_id;
                                tmpStoreList.push(parseInt(a.store_id));
                                storesLists.push(store);
                            }
            
                        });

                        var _stores = storesLists;
                        var _methods = $scope.getMethods(1); //return
                        var _address = checkoutModel.getCheckoutAddress();
                        //here we check if we can set the pickupatstore  method for each store
                        _stores.map(function(store) {

                            if (store.country_id == _address.country_id &&
                                store.state_id == _address.state_id &&
                                store.city_id == _address.city_id) {
                                //put all the egss in the basket
                                store.methods = _methods;
                            } else {
                                //just put one
                                store.methods = _methods.slice(0, 1);
                            }
                        });

                        $scope.shippingStoresList = _stores;  
                    }
                }
            )
        }

        $scope.buildStoreCartList = function() {


            var tmpCartList = cartModel.getTmpCartList();
            var cartList = [];
            cartModel.getCartList().then(
                response => {
                    cartList = response.data
                    if (tmpCartList.length > 0 && cartList.length > 0) {
                        //merge both into the cart list
                        cartList = cartList.concat(tmpCartList);
                    }
        
                    var storeProductlistTmp = [];
                    var storeProductlist = [];
        
        
                    if (cartList.length > 0) {
        
                        cartList.map(function(a, b) {
                            console.log(a);
                            if (storeProductlistTmp[parseInt(a.store_id)] === undefined) {
                                storeProductlistTmp[parseInt(a.store_id)] = {
                                    id: parseInt(a.store_id),
                                    name: a.store_name,
                                    amount: 0,
                                    products: []
                                };
                                storeProductlistTmp[parseInt(a.store_id)].amount += (a.price * a.quantity);
                                storeProductlistTmp[parseInt(a.store_id)].products.push(a);
                            } else {
                                storeProductlistTmp[parseInt(a.store_id)].products.push(a);
                                storeProductlistTmp[parseInt(a.store_id)].amount += (a.price * a.quantity);
                            }
                        });
                    }
                    //clean list for ngrepeat
                    if (storeProductlistTmp.length > 0) {
                        storeProductlistTmp.forEach(function(a, b) {
                            storeProductlist.push(a);
                        });
        
                        storeProductlistTmp = null;
                    }
        
                    $scope.cartParsedList = storeProductlist;
                    $scope.cartList = cartList;
                },
                error => {
                    if (tmpCartList.length > 0 && cartList.length > 0) {
                        //merge both into the cart list
                        cartList = cartList.concat(tmpCartList);
                    }
        
                    var storeProductlistTmp = [];
                    var storeProductlist = [];
        
        
                    if (cartList.length > 0) {
        
                        cartList.map(function(a, b) {
                            console.log(a);
                            if (storeProductlistTmp[parseInt(a.store_id)] === undefined) {
                                storeProductlistTmp[parseInt(a.store_id)] = {
                                    id: parseInt(a.store_id),
                                    name: a.store_name,
                                    amount: 0,
                                    products: []
                                };
                                storeProductlistTmp[parseInt(a.store_id)].amount += (a.price * a.quantity);
                                storeProductlistTmp[parseInt(a.store_id)].products.push(a);
                            } else {
                                storeProductlistTmp[parseInt(a.store_id)].products.push(a);
                                storeProductlistTmp[parseInt(a.store_id)].amount += (a.price * a.quantity);
                            }
                        });
                    }
                    //clean list for ngrepeat
                    if (storeProductlistTmp.length > 0) {
                        storeProductlistTmp.forEach(function(a, b) {
                            storeProductlist.push(a);
                        });
        
                        storeProductlistTmp = null;
                    }
        
                    $scope.cartParsedList = storeProductlist;
                    $scope.cartList = cartList;
                }
            )

        }

        $scope.getTotal = function() {
            var total = 0;
            angular.forEach($scope.cartList, function(value, key) {
                total += value.quantity * value.price;
            });
            var shipping_amount = ($scope.shipping_amount == null) ? 0 : $scope.shipping_amount;
            return total + shipping_amount;
        };

        $scope.updateQuantity = function(id, method, quantity) {

            var cartList = [];
            cartModel.getCartList().then(
                response => {
                    cartList = response.data
                    cartList.map(function(a) {
                        if (a.id == id) {
                            if (method == 'add') {
                                a.quantity = a.quantity + 1;
                            } else if (method == 'remove')
                                a.quantity = a.quantity - 1;
                            if (a.quantity <= 0) {
                                a.quantity = 1;
                            }
                        }
                    });
        
                    cartModel.setCartList(cartList);
                    $scope.cartList = cartList;
                    $scope.buildStoreCartList();
                    $scope.getTotal();
                },
                error => {
                    cartList.map(function(a) {
                        if (a.id == id) {
                            if (method == 'add') {
                                a.quantity = a.quantity + 1;
                            } else if (method == 'remove')
                                a.quantity = a.quantity - 1;
                            if (a.quantity <= 0) {
                                a.quantity = 1;
                            }
                        }
                    });
        
                    cartModel.setCartList(cartList);
                    $scope.cartList = cartList;
                    $scope.buildStoreCartList();
                    $scope.getTotal();
                }
            )
        };

        $scope.getCountry = function(val) {
            return locationModel.getCountry(val).then(function successCallback(response) {
                return response.data.map(function(value) {
                    //return value.name;
                    return {
                        name: value.name,
                        id: value.id
                    };
                });
            });
        };

        $scope.getState = function(val) {
            return locationModel.getState($scope.new_shipping_address.country_id, val).then(function successCallback(response) {
                return response.data.map(function(value) {
                    //return value.name;
                    return {
                        name: value.name,
                        id: value.id
                    };
                });
            });
        };
        $scope.getCities = function(val) {
            return locationModel.getCity($scope.new_shipping_address.state_id, val).then(function successCallback(response) {
                return response.data.map(function(value) {
                    return {
                        name: value.name,
                        id: value.id
                    };
                });
            });
        };
        $scope.onSelect = function($item, $model, $label) {
            $scope.new_shipping_address.state = '';
            $scope.new_shipping_address.state_id = '';
            $scope.new_shipping_address.city = '';
            $scope.new_shipping_address.city_id = '';
            $scope.new_shipping_address.country_id = $item.id;
            $scope.si_country = $item;
        };
        $scope.onSelectState = function($item, $model, $label) {
            $scope.new_shipping_address.city = '';
            $scope.new_shipping_address.city_id = '';
            $scope.new_shipping_address.state_id = $item.id;
            $scope.bi_state = $item;
        };
        $scope.onSelectCity = function($item, $model, $label) {
            $scope.new_shipping_address.city_id = $item.id;
            $scope.si_city = $item;
        };

        $scope.getMethods = function(_return) {

            var _methods = [{
                    'id': 1,
                    'carrier': 'Fedex',
                    'method': "Envio",
                    'information': "Envio general Fedex",
                    'price': 99
                },
                {
                    'id': 2,
                    'carrier': 'Tienda',
                    'method': "Tienda",
                    'information': "Recoger en tienda",
                    'price': 0
                },
            ];

            if (_return) {
                return _methods;
            } else {
                $scope.methods = _methods;
            }
        };

        $scope.getTotalShippingAmount = function() {

            var amount = 0;
            //stores maps should be done here
            Object.values($scope.shipping_method).forEach(function(a, b) {
                $scope.methods.forEach(function(x, y) {
                    if (a.method.id == x.id) {
                        amount += a.method.price;
                    }
                })
            });

            return amount;
        };

        $scope.getStoreShippingAmount = function(store) {
            var key = "_" + store.name.toLowerCase().replace(/\s/g, '_') + "_";
            if ($scope.shipping_method.hasOwnProperty(key)) {
                amount = $scope.shipping_method[key].method.price;
            } else {
                amount = 0;
            }
            return amount;
        }

        $scope.getStep = function() {

            if (!$auth.isAuthenticated()) {
                //redirectLogin
                $location.path('/login');
            }

            $scope.step = 0;

            if ($location.$$path == '/checkout/1') {
                $scope.billing_info = {
                    name: "",
                    lastname: "",
                    email: "",
                    address: "",
                    phone: "",
                    city: "",
                    zipcode: "",
                    country: ""
                };
                $scope.shipping_info = $scope.billing_info;
                $scope.same = false;
                $scope.step = 1;
                $scope.getSavedForm1();
            } else if ($location.$$path == '/checkout/2') {
                $scope.step = 2;
                $scope.buildStoreShippingList();
                $scope.country = "";
                $scope.state = "";
                $scope.zipcode = "";
                $scope.selectedMethod = "";
                $scope.methodName = "";
                $scope.getSavedForm2();
                $scope.loadUserShippingMethod();

            } else if ($location.$$path == '/checkout/3') {
                cartModel.getCartList().then(
                    response => {
                        var list = []
                        var sum = 0
                        list = response.data
                        list.map(function(item) {
                            sum+=item.price;
                        });
                        $scope.cardListTotal = sum
                    }
                )
                $scope.paymethod = checkoutModel.getPayMethod();
                $scope.step = 3;
                //$scope.getSavedForm3();
            } else if ($location.$$path == '/checkout/4') {
                $scope.step = 4;

                $scope.buildStoreCartList();

                $scope.shipping_address = checkoutModel.getCheckoutAddress();
                $scope.shipping_method = checkoutModel.getShippingMethod();
                $scope.payment_method = checkoutModel.getPayMethod();
                $scope.getMethods();
                $scope.shipping_amount = $scope.getTotalShippingAmount();

                userModel.getUser().then(function(response) {
                    $scope.user = response.data.data;
                }, function(response) {
                    $scope.needToLogin = true;
                });
            } else if ($location.$$path.match(/^\/checkout\/5\/[a-z0-9]{13}-[a-z0-9]{13}$/)) {
                //step 5 dahh
                userModel.getUser().then(function(response) {
                    $scope.user = response.data.data;
                    $scope.needToLogin = false;
                    var vb_order_id = $routeParams.vb_order_id;
                    $scope.vb_order_id = vb_order_id;

                    ordersModel.getOrderInfo(vb_order_id).then(function(response) {

                        if (response.status == 200) {
                            var order = response.data.data;
                            //check the paymethod
                            //if ( )
                            $scope.total_amount = response.data.data.total_amount;

                        } else if (response.status == 202) {
                            $scope.already_paid = true;
                            console.log('Already payed');
                        }

                    }, function(response) {
                        console.log("VB ORDER ERROR");
                        //order not found
                    });
                }, function(response) {
                    $scope.needToLogin = true;
                    $location.path('/cart');
                });
            }
        };
        $scope.getSavedForm1 = function() {

            $scope.shipping_address = [];
            shippingAddressModel.get().then(function(response) {
                $scope.authorized = true;

                if (response.status == 200) {
                    $scope.shipping_address = response.data.data;
                }

                if (response.status == 204) {
                    //Check if we have shipping addres in the storage
                    var ca = checkoutModel.getCheckoutAddress();

                    if (ca) {
                        //if we are here is because is not saved in the
                        $scope.shipping_address.push(ca);
                        shippingAddressModel.save(ca).then(function successCallback(response) {
                            if (response.status == 201) {
                                $scope.getSavedForm1();
                                $scope.new_shipping_address = {};
                            }
                        });

                    }

                }

            }, function(response) {

                $scope.shipping_address = [];

                if (response.status == 401) {
                    $scope.authorized = false;
                    //check if we have a shipping_addres in the session
                    var ca = checkoutModel.getCheckoutAddress();
                    if (ca) {
                        $scope.shipping_address.push(ca);
                    }
                }


            });
        };

        $scope.addShippingAddress = function(form) {

            var hasErrors = false;
            var o = $scope.new_shipping_address;
            $scope.shippingaddresMessages.prevError = false;

            $scope.shippingaddresMessages.phoneError = false;
            $scope.shippingaddresMessages.zipcodeError = false;

            
            if(!o.phone || !o.phone.match(/^[\d]+$/) || o.phone.length < 6 || o.phone.length > 10 ){
                $scope.shippingaddresMessages.phoneError = true;
                hasErrors = true;
            }

            if(!o.zipcode.match(/^[\d]+$/) || o.zipcode.length != 5 ){
                $scope.shippingaddresMessages.zipcodeError = true;
                hasErrors = true;
            }

            if (form.$invalid && hasErrors ) {
                $scope.clicked = true;
                return;
            } else {
                $scope.clicked = false;
            }

            if (o == undefined) {
                $scope.shippingaddresMessages.prevError = true;
            }

            var shipping_address = {
                name: o.name,
                lastname: o.lastname,
                email: o.email,
                country_id: o.country_id,
                country_name: o.country,
                state_id: o.state_id,
                state_name: o.state,
                city_id: o.city_id,
                city_name: o.city,
                phone: o.phone,
                zipcode: o.zipcode,
                address: o.address,
                additional_info: o.additional_info,
                colony: o.colony,
                id  : o.id || '',
            };

            if ($scope.authorized == false) {
                $scope.new_shipping_address = {};
                $scope.shipping_address.push(shipping_address);
                return;
            }

            if ( $scope.edit_sa ){
                console.log(shipping_address);
                shippingAddressModel.put(shipping_address).then(function successCallback(response) {
                    if (response.status == 201) {
                        $scope.shippingaddresMessages.added = true;
                        $scope.shippingaddresMessages.removed = false;
                        $scope.shippingaddresMessages.error = false;
                        $scope.getSavedForm1();
                        $scope.new_shipping_address = {};
                    }else if(response.status == 200){
                        $scope.shippingaddresMessages.sucess = true;
                    }
                }, function(response) {

                    if (response.status == 412) {
                        $scope.shippingaddresMessages.formError = true;

                    }

                });
            }else{
                shippingAddressModel.save(shipping_address).then(function successCallback(response) {
                    if (response.status == 201) {
                        $scope.shippingaddresMessages.added = true;
                        $scope.shippingaddresMessages.removed = false;
                        $scope.shippingaddresMessages.error = false;
                        $scope.getSavedForm1();
                        $scope.new_shipping_address = {};
                    }
                }, function(response) {

                    if (response.status == 412) {
                        $scope.shippingaddresMessages.formError = true;

                    }

                });
            }
        };

        $scope.cleanShippingAddress = function(){

            $scope.new_shipping_address = {};
            $scope.edit_sa = false;
        };

        $scope.deleteShippingAddress = function(id) {

            if (!$scope.authorized) {
                //if not authorized just remove
                checkoutModel.deleteCheckoutAddress();
                $scope.shipping_address = [];
                $scope.shippingaddresMessages.removed = true;
                return;
            }

            shippingAddressModel.delete(id).then(function(response) {
                if (response.status == 200) {
                    $scope.shippingaddresMessages.removed = true;
                    $scope.shippingaddresMessages.error = false;
                    $scope.getSavedForm1(); //reset the form
                }
            }, function(response) {
                $scope.shippingaddresMessages.removed = false;
                $scope.shippingaddresMessages.error = true;
            })
        };

        $scope.setShippingAddress = function(shipping_address) {

            console.log("setting shipping address");
            checkoutModel.setCheckoutAddress(shipping_address);
            $location.path('/checkout/2');

        };

        $scope.editShippingAddress = function(shipping_address){

            $scope.edit_sa = true;

            $scope.new_shipping_address = shipping_address;
            $scope.new_shipping_address.city_id    = shipping_address.city_id;
            $scope.new_shipping_address.state_id   = shipping_address.state_id;
            $scope.new_shipping_address.country_id = shipping_address.country_id;
            $scope.new_shipping_address.city    = shipping_address.city_name;
            $scope.new_shipping_address.state   = shipping_address.state_name;
            $scope.new_shipping_address.country = shipping_address.country_name;
        };

        $scope.clean_edit = function(){
            $scope.edit_sa = false;
            $scope.new_shipping_address = {};
        }

        $scope.getSavedForm2 = function() {

            var shipping_address = checkoutModel.getCheckoutAddress();
            var shipping_method = checkoutModel.getShippingMethod();


            if (shipping_address.country_id !== null) {
                $scope.country = shipping_address.country_name;
                $scope.state = shipping_address.state_name;
                $scope.city = shipping_address.city_name;
                $scope.zipcode = shipping_address.zipcode;

                if (shipping_method !== null) {
                    $scope.shipping_method = shipping_method;
                }

            }
        };

        $scope.init = function() {
            $scope.clicked = false;
            $scope.new_shipping_address={
                name:'',
                lastname:'',
                email:'',
                phone:'',
                address:'',
                colony:'',
                zipcode:'',
                country:'',
                state:'',
                city:'',
                additional_info:''
            }
            $scope.getStep();
        };

        $scope.init();

        $scope.validate = function(form, step) {

            $scope.clicked = true;

            if (form.$valid) {
                if (step == 2) {
                    var checkout = {
                        'billing_info': $scope.billing_info,
                        'shipping_info': $scope.shipping_info
                    };

                    checkoutModel.setCheckoutInfo(checkout);
                    $location.path('checkout/' + step);
                } else if (step == 3) {
                    console.log('Error', $scope.shipping_method)
                    var shipping_method = $scope.shipping_method;
                    if (Object.keys(shipping_method).length == $scope.shippingStoresList.length) {
                        checkoutModel.setShippingMethod(shipping_method);
                        $location.path('/checkout/' + step);
                    } else if (Object.keys(shipping_method).length == 0) {

                        $timeout(function() {
                            $scope.shippingMethodErrors.empty = true;
                            $scope.shippingMethodErrors.missing = false;
                        });
                        return;
                    } else if (Object.keys(shipping_method).length < $scope.shippingStoresList.length) {

                        $timeout(function() {
                            $scope.shippingMethodErrors.empty = false;
                            $scope.shippingMethodErrors.missing = true;
                        });
                        return;
                    }


                } else if (step == 4) {
                    //payment method
                    if ($scope.paymethod === undefined || $scope.paymethod == null) {
                        $scope.paymethodsErrors.empty = true;
                        return;
                    }
                    var paymethod = $scope.paymethod;
                    checkoutModel.setPayMethod(paymethod);
                    $location.path('/checkout/' + step);
                }

            }


        };

        $scope.changeCountry = function(){
            if( !$scope.new_shipping_address.country ||  $scope.new_shipping_address.country.length == 0){
                $scope.new_shipping_address.country_id=null;
                $scope.new_shipping_address.state_id=null;
                $scope.new_shipping_address.state='';
                $scope.new_shipping_address.city_id=null;
                $scope.new_shipping_address.city='';
            }
        }

        $scope.changeState = function(){
            if( !$scope.new_shipping_address.state ||  $scope.new_shipping_address.state.length == 0){
                $scope.new_shipping_address.state_id = null;
                $scope.new_shipping_address.city_id=null;
                $scope.new_shipping_address.city='';
            }
        }

        $scope.backToCart = function (){
            window.location.href="/cart";
        }

        $scope.confirmOrder = function() {
            $scope.loading = true;
            $scope.processingOrder = true;
            $scope.cart_info.processing = true;

            //check if the user is really loged in
            userModel.getUser().then(function(response) {
                //yep and send all the data related to the event
                var sa = checkoutModel.getCheckoutAddress();
                var pm = checkoutModel.getPayMethod();
                var sm = checkoutModel.getShippingMethod();
                var cart_products = [];
                var pi = {};

                cartModel.getCartList().then(
                    response => {
                        cart_products = response.data
                        var storeProductlistTmp = [];
                        var storeProductlist = [];

                        if (cart_products.length > 0) {

                            cart_products.map(function(a, b) {
                                console.log(a);
                                if (storeProductlistTmp[parseInt(a.store_id)] === undefined) {
                                    storeProductlistTmp[parseInt(a.store_id)] = {
                                        id: parseInt(a.store_id),
                                        name: a.store_name,
                                        amount: 0,
                                        products: []
                                    };
                                    storeProductlistTmp[parseInt(a.store_id)].amount += (a.price * a.quantity);
                                    storeProductlistTmp[parseInt(a.store_id)].products.push(a);
                                } else {
                                    storeProductlistTmp[parseInt(a.store_id)].products.push(a);
                                    storeProductlistTmp[parseInt(a.store_id)].amount += (a.price * a.quantity);
                                }
                            });
                        }
                        //clean list for ngrepeat
                        if (storeProductlistTmp.length > 0) {
                            storeProductlistTmp.forEach(function(a, b) {
                                storeProductlist.push(a);
                            });

                            storeProductlistTmp = null;
                        }
                        cart_products = storeProductlist;
                        //end parsing the cart

                        cart_products = JSON.stringify(cart_products);

                        var orderInfo = {
                            shipping_address_id: sa.id,
                            paymethod: pm,
                            shipping_method: sm,
                            pay_info: pi,
                            products: cart_products,
                            total_amount: $scope.getTotal(),
                        };

                        ordersModel.confirmOrder(orderInfo).then(function(response) {
                            $scope.processingOrder = false;
                            $scope.cart_info.processing = false;
                            $scope.loading = false;

                            if(checkoutModel.getPayMethod() === 'oxxo_pay' || checkoutModel.getPayMethod() === 'direct_transfer') {

                                //ok response so we clean the cart and the order info
                                cartModel.cleanCart();
                                checkoutModel.deleteCheckoutAddress();
                                checkoutModel.deleteShippingMethod();
                                checkoutModel.deletePayMethod();

                                var tmpcart = cartModel.getTmpCartList();
                                if (tmpcart.length > 0) {
                                    cartModel.setCartList(tmpcart);
                                    cartModel.cleanTmpCartList();
                                }

                            }

                            if (response.status == 200) {

                                $scope.goToPayPage(response.data.data);

                            } else if (response.status == 202) {
                                //is paypal or oxxo_pay (pending payments)
                                var r = response.data.data;
                                if ( r.paymethod == 'oxxo_pay'  ){
                                    //print the reference
                                    $location.path('/checkout/oxxopay-referenciapago/'+r.vb_custom_order_id);

                                }else if ( r.paymethod == 'paypal'){
                                    //$location.path('/checkout/5/' + r.vb_custom_order_id);
                                    $location.path('/checkout/paypal/pago/' + r.vb_custom_order_id);
                                    //$location.path('/checkout/');
                                    //$scope.pay_paypal(response.data.data);
                                }else  if (r.paymethod == 'direct_transfer'){
                                    $location.path('/checkout/referencia-pago/' + r.vb_custom_order_id);
                                }else  if (r.paymethod == 'conekta_credit_card'){
                                    $location.path('checkout/conekta_credit_card/pago/' + r.vb_custom_order_id)
                                }else{
                                    console.log('What paymethod are you using?');
                                }
                            }

                        }, function(response) {
                            if (response.status == 401) {
                                $scope.processingOrder = false;
                                $scope.cart_info = {
                                    processing: false,
                                    error: true
                                };
                                $scope.cart_info = {
                                    processing: false,
                                    error: false,
                                    stock: true,
                                    fatal: false
                                };
                                $scope.loading = false;

                            } else if (response.status == 510) {
                                $scope.processingOrder = false;
                                $scope.cart_info = {
                                    processing: false,
                                    error: false,
                                    stock: false,
                                    fatal: true,
                                    message: response.data.message
                                };
                                $scope.loading = false;
                            } else {
                                $scope.processingOrder = false;
                                $scope.cart_info = {
                                    processing: false,
                                    error: true,
                                    stock: false,
                                    fatal: false,
                                    message: response.data.message
                                };
                                $scope.loading = false;
                            }

                        });
                    }
                )
                
            }, function(response) {
                //nope
                $scope.processingOrder = false;
                $scope.cart_info.processing = false;
                $location.path('/cart');
            });
        };


    }
]);
