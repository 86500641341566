myApp.controller( 'emailConfirmController', [
                        '$scope',
                        'userModel',
                        '$location',
                        '$routeParams',                        
                        function(
                                $scope, 
                                userModel,
                                $location,
                                $routeParams){

    $scope.confirmed = false;

    angular.extend($scope,userModel,$location,$routeParams,{

        initConfirmEmail : function(){
            var code = $routeParams.activation_code;
            userModel.confirmEmail(code).then(
                function successCallback(response){
                    if(response.status == 200){
                        $scope.confirmed = true;
                    }
                    if(response.status == 204){
                        $scope.confirmed = true;
                    }
                }, function errorCallback(response){
                    if(response.status == 401){
                        $scope.confirmed = false;
                    }

                    if(response.status == 204){
                        $scope.confirmed = true;
                    }
            });
        }

    });

}]);