'use strict';
myApp.controller('cartMenuController', function ($scope, cartModel,$rootScope) {

    $scope.getCartList = function(){
        cartModel.getCartList().then(
            response => {
                if(response.data){
                    $scope.cartList = response.data
                }
            }
        )
    };

    $scope.init = function () {
        $scope.cartList = [];
        $scope.getCartList();
    };

    $scope.reloadCart = function(){
        $scope.cartList = $scope.getCartList();
    };

    $scope.init();

    $scope.redirect = function (option) {
        if (option == 'cart') {
            window.location = '/cart';
        } else {
            window.location = '/checkout/1';
        }
    };

    $scope.removeItem = function(id){

        event.preventDefault();

        var cartList = []
        var tmpCartList = [];

        cartModel.getCartList().then(
            response => {
                if(response.data){
                    cartList = response.data
                    cartList.forEach( function(a,b){
                        if(a.id != id ){
                            tmpCartList.push(a);
                        }
                    });
            
                    $scope.cartList = tmpCartList;
                    cartModel.setCartList(tmpCartList);
            
                    if(tmpCartList.length == 0){
                        cartModel.cleanTmpCartList();
                    }
            
                    $rootScope.$broadcast('reloadCart',tmpCartList);
                }
            },
            error => {
                $rootScope.$broadcast('reloadCart',tmpCartList);
            }
        )

    };

    $scope.$on('reloadCartMenu',function(event,args){
        $scope.reloadCart();
    });

});
