myApp.controller('orderController', function($scope, ordersModel, userModel, $location, $routeParams) {

    $scope.error = false
    $scope.confirm = {};
    $scope.confirm.auth_number = '';
    $scope.confirm.date = '';
    $scope.deposit_date = '';
    $scope.orders = [];
    $scope.order = {};
    $scope.order_status_codes = [];
    $scope.order_status_codes['PP'] = 'Pago Pendiente';
    $scope.order_status_codes['PC'] = 'Pago Completado';
    $scope.order_status = [
        {id : 'AA',label :'Todos'},
        {id : 'PP',label :'Pago Pendiente'},
        {id : 'PC',label :'Pago Completado'},
    ];
    $scope.altInputFormats = ['M!/d!/yyyy'];
    $scope.loading = false;
    $scope.paymethods = {
        direct_transfer : {  ES : 'Deposito Oxxo, Banamex o HSBC' },
        oxxo_pay : { ES : 'OXXO Pay'  },
        paypal  : { ES : 'PayPal' },
        conekta_credit_card : { ES : 'Tarjeta de Credito'},
    };
	$scope.datepicker = false;
    $scope.dt = {};
	$scope.dt.show_payment_confirmed = false;
    $scope.dt.info_sent = false;

    $scope.maxSize = 10;//defaultValue
    $scope.lastPage = null;
    $scope.pageTotal = null;
    $scope.messages = {}
    $scope.user = {};

    $scope.dateOptions = {

        formatYear: 'yy',
        maxDate: new Date(2020, 5, 22),
        startingDay: 1
    };
    $scope.format = 'dd/MM/yyyy';

    function getDayClass(data) {
        var date = data.date,
            mode = data.mode;
        if (mode === 'day') {
            var dayToCheck = new Date(date).setHours(0, 0, 0, 0);

            for (var i = 0; i < $scope.events.length; i++) {
                var currentDay = new Date($scope.events[i].date).setHours(0, 0, 0, 0);

                if (dayToCheck === currentDay) {
                    return $scope.events[i].status;
                }
            }
        }
    };

    angular.extend($scope, {

		open : function(){
			$scope.datepicker = !$scope.datepicker;
		},

        getOrders: function(options) {

            $scope.orders = ordersModel.getOrders(options).then(function(response) {
                if (response.status == 200){
                    $scope.orders = response.data.data.data;
                    $scope.lastPage = response.data.data.last_page;
                    $scope.pageTotal = response.data.data.total;
                    $scope.messages.not_found = false;
                }else if (response.status == 204){

                    $scope.messages.not_found = true;
                }

            }, function(response) {
                $scope.orders = [];
            });

        },

        getOrdersPage : function(form){

            var options = {
                all     : 1 ,
                page    : $scope.currentPage ,
                email   : $scope.filters.email,
                status  : $scope.filters.status,
            };

            $scope.orders = ordersModel.getOrders(options).then(function(response) {
                if ( response.status == 200 ){
                    $scope.orders = response.data.data.data;
                    $scope.orders_page_lastPage = response.data.data.last_page;
                    $scope.orders_page_pageTotal = response.data.data.total;
                    $scope.messages.not_found = false;
                }else if (response.status == 204 ){
                    $scope.orders = [];
                    $scope.messages.not_found = true;
                }

            }, function(response) {
                $scope.orders = [];
            });
        },

		saveDirectTrasferInfo : function(){
            $scope.error = false
			var confirm_info = $scope.confirm;
            var order_id = $scope.order.id;
            if($scope.confirm.auth_number && $scope.confirm.date){
                ordersModel.saveDirectTrasferInfo(order_id,confirm_info).then(function(response){
                    if(response.status == 200){
                        //already paid
                        $scope.order.status_code = 'PC';
                    }else if (response.status == 201 ){
                        //updated
                        $scope.order.status_code = 'PC';
                        $scope.dt.show_payment_confirmed = true;
                    }
                },function(response){
                    console.log("error");
                });
            }else{
                $scope.error = true
            }

		},

        confirmDirectTrasnferInfo : function(){
            $scope.error = false
			var confirm_info = $scope.confirm;
            if($scope.confirm.date){
                ordersModel.confirmDirectTrasnferInfo($scope.order.id,confirm_info).then(function(response){
                    if (response.status == 200){
                        $scope.deposit_date = response.data.data.deposit_date
                        $scope.info = { status : true };
                        $scope.order.status_code = 'PC';
                    }
                },function(response){
                    console.log("ERROR");
                });
            }else{
                $scope.error = true
            }
        },

        getOrder: function() {
            var vb_order_id = $routeParams.vb_order_id;

            $scope.order = ordersModel.get(vb_order_id).then(function(response) {

                if (response.status == 200) {
                    $scope.errors = {
                        notfound: false
                    };
                    $scope.order = response.data.data;

                    if($scope.order.paymethod == 'direct_transfer' && $scope.order.extra_info.authorization_number !== 'undefined' &&  $scope.order.extra_info.authorization_number > 0){
                        $scope.dt.info_sent = true;
                    }

                } else if (response.status == 204) {
                    $scope.order = {};
                    $scope.errors = {
                        notfound: true
                    };
                }

            }, function(response) {
                if (response.status == 500 ){
                        $scope.errors = { fatal_error : true };
                }

            });
        },
        getadminorder: function() {
            userModel.userHasStore().then(function successCallback(response){
                if(response.status == 200){
                    $scope.user.hasStore = response.data.data.has_store == "1" ? true: false;
                    $scope.user.name = userModel.getSavedUser();
                    $scope.user.id = response.data.data.user_id;
                    $scope.user.type  = response.data.data.type;
                    $scope.user.store_status = response.data.data.store_status;

                }
            }, function errorCallback(response){
            });
            console.log($scope.user)

            var custom_order_id = $routeParams.vb_order_id;

            $scope.order = ordersModel.getAdminOrderDetail(custom_order_id).then(function(response) {

                if (response.status == 200) {
                    $scope.errors = {
                        notfound: false
                    };
                    $scope.order = response.data.data;
                    $scope.deposit_date = response.data.data.extra_info.deposit_date;
                    console.log($scope.order,"FUCK");

                } else if (response.status == 204) {
                    $scope.order = {};
                    $scope.errors = {
                        notfound: true
                    };
                }

            }, function(response) {

            });

        },

        cancelStoreOrder: function(order_id) {
            /*cancel the order related to a store*/

        },
        print: function() {
            var divName = document.getElementById('ORDER');
            var printContents = divName.innerHTML;
            var popupWin = window.open('', '_blank', 'width=600,height=600');
            popupWin.document.open();
            var styles = "<link href='//fonts.googleapis.com/css?family=Oxygen:400,300,700' rel='stylesheet' type='text/css'><link rel='stylesheet' href='//cdn.linearicons.com/free/1.0.0/icon-font.min.css'><link rel='stylesheet' type='text/css' href='/bower_components/jquery-ui/themes/base/all.css'><link rel='stylesheet' type='text/css' href='/bower_components/bootstrap/dist/css/bootstrap.min.css'><link rel='stylesheet' type='text/css' href='/css/colors/default.css'><link rel='stylesheet' type='text/css' href='plugins/owl-carousel/owl.carousel.css' media='screen'><link rel='stylesheet' type='text/css' href='/bower_components/font-awesome/css/font-awesome.css'><link rel='shortcut icon' href='/img/favicon.ico' type='image/x-icon'><link rel='stylesheet' type='text/css' href='/css/custom.css'/><style>.hide-from-print{display: none !important;}</style>";
            popupWin.document.write('<html><head>'+styles+'</head><body onload="window.print()">' + printContents + '</body></html>');
            popupWin.document.close();
        },

        cancelOrder: function(){
            $location.path('/user/dashboard/pedidos-admin');
        }

    });

});
