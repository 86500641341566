myApp.controller( 'homeController', ['$scope','storeModel','productModel',
'locationModel','$location','localStorageService','$rootScope',
'slidesCarousellModel','$routeParams',
function($scope,storeModel,productModel,locationModel,$location ,
	localStorageService,$rootScope,slidesCarousellModel,$routeParams ){

	$scope.bannerStores = [];
	$scope.lastestProducts = [];
	//$scope.carousel = {};
	$scope.query_string = '';
	$scope.myInterval = 5000;
    $scope.noWrapSlides = false;
    $scope.active = 0;
	$scope.searchobj = {};
	$scope.slides = [];

	angular.extend($scope,storeModel,locationModel,$location,$routeParams,{
	init : function(){
			storeModel.getFeaturedStores().then( function successCallback(response){
				$scope.bannerStores  = response.data.data.data.map(function(value){

					if(value.is_external_url ==  '1' ){
						var external_url = value.external_url.replace('https:','http:');
						value.imgurl = external_url;
					}else{
						value.imgurl = baseUrl + '/images/publication/' + value.id + '/main.jpeg';
					}

					return value;
				});

			},
			function errorCallback(response){
				console.log('No Stores Found');
			}
		 );
	 },
	 initCarousell : function(){

		 slidesCarousellModel.getcarousell(window.location.pathname).then(function(response){
			 if(response.status == 200 ){
				 $scope.slides = response.data.data;
			 }
		 },function(response){

		 });

	 },
	 initProducts : function(){
		 productModel.getRandProducts({ limit : 9, latest : 1 }).then(function successCallback(response){

			 $scope.lastestProducts = response.data.data;
			 $scope.lastestProducts = response.data.data.map(function(item){
				var slug = item.title.toLowerCase().replace(/[,;\-\_\/\\\.]/g,"").replace(/\_{2,}/,'_').replace(/\s/g,"_");
				item.fixed_url = '/publicacion/'+slug+"-"+item.id;
			 	if(item.is_external_url == '1' ){
					var external_url = item.external_url.replace('https://','http://');
			 		item.pubImgUrl = external_url;
			 	}else{
			 		item.pubImgUrl = baseUrl + "/images/publication/"+item.id+"/main.jpeg";
			 	}

				return item;
			 });
		 });
	 },
	 getLocation : function(term){

		return locationModel.getLocation(term).then(function successCallback(response){
			 return response.data.data;
			});
		//return [];
	 },
	 onSelectLocation : function($item, $model, $label){
		//debugger;
		$scope.searchobj.city = $item.city_name;
		$scope.searchobj.state = $item.state_name;
		$scope.searchobj.label = $item.city_state;

	 },
	 setHomeSearchTerm : function(){
		var search = localStorageService.get('home_search');
		if(search !== ''){
			search = JSON.parse(search);
			$scope.search = search;
		}
	 },
	 search : function(){

		var path = '/'	;
		localStorageService.set('home_search',JSON.stringify($scope.searchobj) );
		var jsonParams = {};
		if($scope.searchobj.state){ jsonParams.estado  = $scope.searchobj.state; }
		if($scope.searchobj.city){ jsonParams.city  = $scope.searchobj.city; }
		jsonParams.query  = $scope.query_string;
		//$location.path(path).search(jsonParams);
		if($location.$$path == path ){
			$rootScope.$broadcast('searchTerm',jsonParams);
		}else{
			$location.path(path).search(jsonParams);
		}

	 },

	});
}]);
