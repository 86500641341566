myApp.controller('conektaPaymentsController', ['$routeParams', '$http', '$scope', '$location', 'checkoutModel', 'cartModel',
    'locationModel', 'shippingAddressModel', 'userModel', '$auth', 'ordersModel', '$timeout',
    function($routeParams, $http, $scope, $location,
        checkoutModel,
        cartModel,
        locationModel,
        shippingAddressModel,
        userModel,
        $auth,
        ordersModel,
        $timeout) {

        $scope.authorized = false;
        $scope.needToLogin = false;
        $scope.processingOrder = false;
        $scope.vb_order_id = null;
        $scope.card = {};
        $scope.errors = {};
        $scope.errors.number = {};
        $scope.errors.expiration = {};
        $scope.errors.cvc = {};
        $scope.errors.name = {};
        $scope.errors.unkwon = {};
        $scope.total_amount = null;
        $scope.payment_success = false;
        $scope.already_paid = false;
        $scope.errormessage = 'Ocurrio un error, intentar más tarde'
        $scope.conekta_key = null

        $scope.env = '', // Or 'sandbox'
        $scope.client = {};


        $scope.initConektaPayment = function(){

            userModel.getUser().then(function(response) {

                $scope.user = response.data.data;
                $scope.needToLogin = false;

                var vb_order_id = $routeParams.vb_order_id;
                $scope.vb_order_id = vb_order_id;

                ordersModel.getOrderInfo(vb_order_id).then(function(response) {

                    if (response.status == 200) {
                        var order = response.data.data;
                        $scope.total_amount = response.data.data.total_amount;
                        $scope.conekta_key = response.data.data.conekta_key;

                    } else if (response.status == 202) {
                        $scope.payment_success = true;
                        console.log('Already payed');
                    }

                }, function(response) {
                    console.log("VB ORDER ERROR");
                    //order not found
                });
            }, function(response) {
                $scope.needToLogin = true;
                $location.path('/cart');
            });
        };

        $scope.removeDisplayedErrors = function(){
            $scope.errors.name = {required :false };
            $scope.errors.number = {invalidate :false };
            $scope.errors.expiration = {invalidate :false };
            $scope.errors.cvc = {invalidate :false };
        };

        $scope.getTokenConekta = function(){
            $scope.card.processing = true;
            this.removeDisplayedErrors();
            let success = true

            if(!$scope.card.name){
                $scope.errors.name = {'required': true};
                success = false
            }
            if(!Conekta.card.validateNumber($scope.card.number)){
                $scope.errors.number = {'invalidate': true};
                success = false
            }
            if(!Conekta.card.validateExpirationDate($scope.card.month, $scope.card.year)){
                $scope.errors.expiration = {'invalidate': true};
                success = false
            }
            if(!Conekta.card.validateCVC($scope.card.cvc)){
                $scope.errors.cvc = {'invalidate': true};
                success = false
            }
            
            if(success){
                Conekta.setPublicKey($scope.conekta_key);
                var tokenParams = {
                    "card": {
                        "number": $scope.card.number,
                        "name": $scope.card.name,
                        "exp_year": $scope.card.year,
                        "exp_month": $scope.card.month,
                        "cvc": $scope.card.cvc
                    }
                };
                Conekta.Token.create(tokenParams, this.successResponseHandler, this.errorResponseHandler); 
            } 
        }

        $scope.successResponseHandler = function(token){
            console.log(token.id)
            $http({
                headers: {'Content-Type': 'application/json'},
                url: baseUrl + '/orders/payconekta',
                method: 'POST',
                data: {
                    token: token.id,
                    vb_order_id: $scope.vb_order_id,
                    user_id: $scope.user.id
                }
            }).then(
                function successCallback(response) {
                    switch (response.status) {
                        case 200:
                            $scope.payment_success = true;
                             //ok response so we clean the cart and the order info
                            cartModel.cleanCart();
                            checkoutModel.deleteCheckoutAddress();
                            checkoutModel.deleteShippingMethod();
                            checkoutModel.deletePayMethod();

                            var tmpcart = cartModel.getTmpCartList();
                            if (tmpcart.length > 0) {
                                cartModel.setCartList(tmpcart);
                                cartModel.cleanTmpCartList();
                            }
                            
                            $scope.email = response.data.data.order.user_email;
                            $scope.address = response.data.data.order.order_address;
                            $scope.city = response.data.data.order.city;
                            $scope.state = response.data.data.order.country;
                            console.log("Pay success")
                            break;
                    }

                },
                function errorCallback(response) {
                    $scope.card.processing = false;
                    $scope.errormessage = response.data.message
                    $scope.errors.unkwon = {'invalidate' : true };
                }
            );
        }

        $scope.errorResponseHandler = function(error) {
            console.log(error)
            $scope.errors.unkwon = {'invalidate': true};
        }

        $scope.printreference = function(){
            window.print();
        };
    }

]);