myApp.controller('directTransferController', [
    '$routeParams',
    '$scope',
    '$location',
    'locationModel',
    'userModel',
    '$auth',
    'ordersModel',
    '$timeout',
    function($routeParams, $scope, $location, $locationModel, userModel, $auth, ordersModel, $timeout) {

        $scope.reference = {};
        
        $scope.initReference = function() {
            console.log("adsassa");
            if ($auth.isAuthenticated()) {

                var vb_order_id = $routeParams.vb_order_id;
                $scope.vb_order_id = vb_order_id;

                ordersModel.getOrderInfo(vb_order_id).then(function(response) {

                    if (response.status == 200) {
                        var order = response.data.data;
                        console.log(order);
                        console.log(order.extra_info);
                        $scope.total_amount = order.total_amount;
                        $scope.banamex_account = order.extra_info.banamex_account;
                        $scope.hsbc_account = order.extra_info.hsbc_account;
                        $scope.reference = order.extra_info.reference;
                        $scope.account_owner = order.extra_info.account_owner;

                    } else if (response.status == 202) {
                        $scope.already_paid = true;
                        console.log('Already payed');
                    }

                }, function(response) {
                    console.log("VB ORDER ERROR");
                });

            } else {
                $location.path('/login');
            }
        };

        $scope.printreference = function() {
            console.log("print");
            window.print()
        };
    }
]);
