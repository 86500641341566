myApp.controller('wishListController', [
    '$scope',
    '$routeParams',
    'wishListModel',    
    '$anchorScroll',
    'cartModel',
    '$location',
    'categoriesModel',
    function (
            $scope,
            $routeParams,            
            wishListModel,                        
            $anchorScroll,
            cartModel,
            $location,
            categoriesModel) {

        $scope.wishList;
        $scope.messages = {};
        $scope.quantity;

        $scope.addToCart = function (product) {
            
            
            var id = product.id;
            //tomamos la lista de localstorage
            var itemsList = [];
            //concatenar ids
            var concatenado = "";
            var exists = false;

            var newList = [];

            cartModel.getCartList().then(
                response => {
                    itemsList = response.data
                    angular.forEach(itemsList, function (val, key) {
                        concatenado = concatenado + ',' + val.productId;
                        if (val.id == id) {
                            exists = true;
                            val[key].quantity++;
                        }
                    });
        
                    if (!exists) {
                        concatenado = concatenado + ',' + id;
                        var img = product.imgurl;
        
                        itemsList.push({
                            id: product.id,
                            price: product.price,
                            picture: img,
                            title: product.title,
                            quantity: 1,
                            store_id : product.store_id,
                            store_name : product.store.name,
                            store_country_id : product.store.country_id,
                            store_state_id : product.store.state_id,
                            store_city_id : product.store.city_id,
                            external_key : product.external_key,
                        });
                        console.log('itemList', itemsList)
                    }
        
                    cartModel.setCartList(itemsList);
                    $location.path('/cart');   
                },
                error => {
                    concatenado = concatenado + ',' + id;
                        var img = product.imgurl;
        
                    itemsList.push({
                        id: product.id,
                        price: product.price,
                        picture: img,
                        title: product.title,
                        quantity: 1,
                        store_id : product.store_id,
                        store_name : product.store.name,
                        store_country_id : product.store.country_id,
                        store_state_id : product.store.state_id,
                        store_city_id : product.store.city_id,
                        external_key : product.external_key,
                    });
        
                    cartModel.setCartList(itemsList);
                    $location.path('/cart');   
                }
            )
                     
            

        };

        angular.extend($scope,wishListModel,{
            init : function(){
                categoriesModel.getCartInvalidCategories().then(function successCallback(response){
                    if(response.status == 200){
                        $scope.invalidCartCategories = response.data.data.map(function(a,b){
                            return a.id;
                        });
                        console.log($scope.invalidCartCategories);
                        wishListModel.get().then(function successCallback(response){
                            if(response.status == 200){
                                $scope.wishList = response.data.data.map(function(a,b){
                                    if(a.external_key)
                                        a.imgurl = a.images[0].external_url
                                    else
                                        a.imgurl = baseUrl + '/images/publication/' + a.id + '/main.jpeg';                                    
                                    a.hide_cart = (a.publication_type == "0" || $scope.invalidCartCategories.indexOf(parseInt(a.category_id)) > 0 ) ? 1 : 0;
                                    return a;
                                });                        
                                
                            }
                            console.log($scope.wishList);
                        });
                        
                    }
                });
            },            
            removeFromWishList : function(product_id){
                
                var id = product_id;
                $scope.messages = {success: false};
                $scope.messages = {error: false};

                wishListModel.remove(id).then(function successCallback(response){
                    if(response.status == 200){
                        $scope.messages = {removed: true};
                        $scope.init();
                    }
                    if(response.status == 204){
                       $scope.messages = {nothing: true};     
                    }
                    
                }, function errorCallback(response){
                    $scope.messages = {error: true};
                    
                });
            }            
        });
    }
]);