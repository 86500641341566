myApp.controller('productSearchController', [
    '$scope',
    'productModel',
    '$routeParams',
    'cartModel',
    '$location',
    'categoriesModel',
    'storeModel',
    'locationModel',
    '$anchorScroll',
    'wishListService',
    'localStorageService',
    function (
            $scope,
            productModel,
            $routeParams,
            cartModel,
            $location,
            categoriesModel,
            storeModel,
            locationModel,
            $anchorScroll,
            wishListService,
            localStorageService) {

    $scope.filters = {};
    $scope.products = null;
    $scope.maxSize = 9;//defaultValue
    $scope.lastPage = null;
    $scope.pageTotal = null;
    $scope.sideFilters = {};
    $scope.sideFilters.categories   = [];
    $scope.sideFilters.brands       = [];
    $scope.categories = [];
    $scope.searchobj = {};
    $scope.wishListMessages = {};
    $scope.invalidCartCategories = [];
    $scope.subcategory_name = '';
    $scope.lastUrlSearch = '';
    $scope.currentPage;
    $scope.USD_2_MXN = 1;
    $scope.brands = [];
    $scope.properties = [];
    $scope.limit = 5;
    $scope.properties_limits = [];
    $scope.order_labels = {
        s_low_high : 'Ordenar x Precio Bajo Alto' ,
        s_high_low :'Ordenar x Precio Alt Bajo' ,
        s_desc : 'Ordenamiento Normal',
    };

    $scope.carousell_slides = [];
    $scope.myInterval = 5000;
    $scope.noWrapSlides = false;
    $scope.active = 0;

    //default optios for the slider
    $scope.slider = {
        minValue : 10,
        maxValue : 20 ,
        options : {
            floor: 0,
            ceil: 100,
            step: 1,
            noSwitching: true,
            translate: function(value) {
              return '$' + value;
            }
        }
    };
    $scope.result_sorting = [
        {id : 's_desc',label:'Ordenamiento Normal'},
        {id : 's_low_high',label:'Ordenar x Precio Bajo Alto'},
        {id : 's_high_low',label:'Ordenar x Precio Alt Bajo'},
    ];
    $scope.$on('searchTerm',function(event, args){
        $scope.filterByQuery(args);
    });

    $scope.addToCart = function (product) {


        var id = product.id;
        var itemsList = []
        //concatenar ids
        var concatenado = "";
        var exists = false;
        var newList = [];
        //tomamos la lista de localstorage
        cartModel.getCartList().then(
            response => {
                if(response.data){
                    itemsList = response.data
                }
        
                angular.forEach(itemsList, function (val, key) {
                    concatenado = concatenado + ',' + val.productId;
                    if (val.id == id) {
                        exists = true;
                        //val[key].quantity++;
                    }
                });
        
                if (!exists) {
                    concatenado = concatenado + ',' + id;
                    var img = product.imgurl;
        
                    itemsList.push({
                        id: product.id,
                        price: (product.currency_code == 'USD') ? product.price * $scope.USD_2_MXN : product.price ,
                        price_USD : (product.currency_code == 'USD') ? product.price  : product.price / $scope.USD_2_MXN,
                        picture: img,
                        title: product.title,
                        quantity: 1,
                        store_name : product.name,
                        store_id   : product.store_id,
                        external_key : product.external_key,
                        currency_code : product.currency_code,
                        store_country_id : product.country_id,
                        store_state_id : product.state_id,
                        store_city_id : product.city_id,
                    });
                }
        
                cartModel.setCartList(itemsList);
        
                $location.url('/cart');
            },
            error => {
                concatenado = concatenado + ',' + id;
                var img = product.imgurl;
    
                itemsList.push({
                    id: product.id,
                    price: (product.currency_code == 'USD') ? product.price * $scope.USD_2_MXN : product.price ,
                    price_USD : (product.currency_code == 'USD') ? product.price  : product.price / $scope.USD_2_MXN,
                    picture: img,
                    title: product.title,
                    quantity: 1,
                    store_name : product.name,
                    store_id   : product.store_id,
                    external_key : product.external_key,
                    currency_code : product.currency_code,
                    store_country_id : product.country_id,
                    store_state_id : product.state_id,
                    store_city_id : product.city_id,
                });
                cartModel.setCartList(itemsList);
                $location.url('/cart');
            }
        )

    };

    angular.extend($scope, productModel, $location, $routeParams,wishListService, {


        toogleShowBrands : function(){
            if ( $scope.limit == $scope.sideFilters.brands.length ){
                $scope.limit = 5;
            }else{
                $scope.limit = $scope.sideFilters.brands.length;
            }

        },
        toogleShowProperties : function(property){
            if (  $scope.properties_limits[property.name] == property.values.length ){
                $scope.properties_limits[property.name] = 5;
            }else{
                $scope.properties_limits[property.name] = property.values.length;
            }

        },
        addToWishList: function(id,index){
            $scope.wishlistindex = index;
             wishListService.add(id).then(function successCallback(response){

                if(response.status == 201){
                    $scope.wishListMessages = {added:true};
                }

                if(response.status == 200){
                    $scope.wishListMessages = {repeated:true};
                }
             },function errorCallback(response){
                $scope.wishListMessages = {error:true};
             });;
        },
        filterBySorting : function(sorting){
            //console.log(sorting);
            $scope.filters.order = sorting;
            $location.search($scope.filters);
            $scope.findProducts();
        },
        filterByQuery : function(params){

            /**
            if params is defined that means that we are from the global search
            So in this case we remove all the other filters and keep the query search
            **/

            if(params !== undefined && Object.keys(params).length > 0 ){

                //we are only seraching for query for the mainbar
                if(Object.keys(params).length == 1 && params.query !== undefined ){
                    var query = params.query
                    $scope.filters = {};//reset this shit
                    $scope.filters.query = query;
                }else{
                    //we have more than one param maybe store name and query
                    if( params.hasOwnProperty('query') ){
                        if(params.query.length == 0){
                            delete params.query;
                        }
                        $scope.filters = params;
                    }else{
                        delete $scope.filters.query;
                    }
                }

                if(params.hasOwnProperty('estado') ){
                    $scope.filters.estado = params.estado;
                    if(params.hasOwnProperty('ciudad') ){
                        $scope.filters.ciudad = params.ciudad;
                    }
                }else{
                    delete $scope.filters.estado;
                    delete $scope.filters.ciudad;
                }
            }

            /**
            If params was not defined that means
            that the query is from the store filters and we keep with our life
            **/
            $scope.filters.page = 1;
            $scope.filters.category_id = null;

            //remove the fitlers related to price
            if($scope.filters.query==''){
                delete $scope.filters.query;
            }
            delete $scope.filters.price_min;
            delete $scope.filters.price_max;
            $location.search($scope.filters);
            $scope.findProducts();
        },
        filterByCategory : function(category_id,subcategory){
            $scope.filters   = $routeParams;
            $scope.filters.category_id = category_id;
            delete $scope.filters.subcategory_id;
            //$scope.selected_category_id = 0;

            if ( category_id == null ){
                delete $scope.filters.category_id;
                delete $scope.filters.subcategory_id;
                delete $scope.filters.price_min;
                delete $scope.filters.price_max;
                delete $scope.filters.brand_ids;
                $location.search($scope.filters);
                $scope.findProducts();
            }

            if( subcategory !== undefined &&  parseInt(subcategory.id) && subcategory.id > 0  ){
                $scope.filters.subcategory_id = subcategory.id;
                $scope.subcategory_name = subcategory.title;
                //$scope.selected_category_id = $scope.filters.category_id;
            }
            //reset the page
            $scope.filters.page = 1;
            $scope.filters.query = null;
            delete $scope.filters.price_min;
            delete $scope.filters.price_max;
            delete $scope.filters.brand_ids;
            $location.search($scope.filters);
            $scope.findProducts();
        },
        filterByBrand : function(brand_id){
            $scope.filters   = $routeParams;
            $scope.filters.page = 1;
            var brands_slug = '';
            //debugger;
            if (brand_id ){
                var foundIndex = $scope.brands.findIndex(function(a){
                    return brand_id == a;
                });

                //not found so we add it
                if ( foundIndex == -1 ){
                    $scope.brands.push(brand_id);
                }else if ( foundIndex >= 0 ){
                    //if it was found and we clicked that mean we want to remove it
                    $scope.brands.splice(foundIndex,1);
                }
            }


            if ($scope.brands.length > 0 ){
                $scope.brands.map(function(a){
                    brands_slug = brands_slug + a +"+";
                });
            }

            //build the daam url

            $scope.filters.brand_ids = brands_slug;
            $location.search($scope.filters);
            $scope.findProducts();
        },
        filterByProperty : function(property_key){
            $scope.filters   = $routeParams;
            $scope.filters.page = 1;
            var properties_slug = '';
            //debugger;
            console.log(property_key);
            if (property_key ){
                var foundIndex = $scope.properties.findIndex(function(a){
                    return property_key.split('_')[0] == a.split('_')[0];
                });

                //not found so we add it
                if ( foundIndex == -1 ){
                    $scope.properties.push(property_key);
                }else if ( foundIndex >= 0 ){
                    //if it was found and we clicked that mean we want to remove it
                    $scope.properties.splice(foundIndex,1);
                }
            }


            if ($scope.properties.length > 0 ){
                $scope.properties.map(function(a){
                    properties_slug = properties_slug + a +"+";
                });
            }

            //build the daam url

            $scope.filters.properties = properties_slug;
            $location.search($scope.filters);
            $scope.findProducts();
        },
        filterByPrice : function(){
            $scope.filters = $routeParams;
            $scope.filters.page = 1;
            $scope.filters.price_min = $scope.slider.minValue;
            $scope.filters.price_max = $scope.slider.maxValue;
            $location.search($scope.filters);
            $scope.findProducts();
        },
        start : function(){
            //reset the page
            $scope.filters   = {};
            $scope.filters.page = 1;
            $location.search($scope.filters);
            $scope.findProducts();
        },
        isSubcategoryInCategory : function(subcategory_id,subcategories){
            var status = false;
            Object.values(subcategories).map(function(a){

                if ( a.id == subcategory_id ){
                    status = true;return;
                }
            });

            return status;
        },
        isInBrands : function(brand_id){
            var status =  $scope.brands.find(function(a){  return a == brand_id }) ;
            return status > 0 ? true : false;
        },
        isInProperties : function(property_key){
            var status =  $scope.properties.find(function(a){  return a == property_key }) || "" ;
            return status.length > 0 ? true : false;
        },
        searchProducts : function(_continue){

            var _continue = true;

            if($scope.invalidCartCategories.length == 0){
                _continue = false;
                categoriesModel.getCartInvalidCategories().then(function successCallback(response){
                    if(response.status == 200){
                        $scope.invalidCartCategories = response.data.data.map(function(a,b){
                            return a.id;
                        });
                        $scope.searchProducts();
                        return;
                    }
                    if(response.status == 204){
                        return;
                    }
                });
            }
            if(_continue){
                $scope.filters = $routeParams;

                if ( $scope.filters.brand_ids !== undefined ){
                    $scope.brands  = $scope.filters.brand_ids.split('+').filter(function(a){
                        if ( a !== ''){
                            return a
                        }
                    });
                }

                if ($scope.filters.properties !== undefined ){
                    $scope.properties  = $scope.filters.properties.split('+').filter(function(a){
                        if ( a !== ''){
                            return a
                        }
                    });
                }

                if( ! ( $scope.filters.page >= 1 &&  $scope.currentPage == null ) ){
                    $scope.filters.page = $scope.currentPage;
                }else{
                    $scope.currentPage = $scope.filters.page;
                }

                $scope.filters.limit = $scope.maxSize;
                if ( $scope.filters.order === undefined ){
                    $scope.filters.order = 's_desc';
                }

                $location.search($scope.filters);
                $scope.findProducts();
            }
        },
        check :function(){
                console.log("ADS");
        },
        findProducts: function () {

            var lastSearchUrl = $location.url();
            localStorageService.set('last_search_url',lastSearchUrl);

            productModel.find($scope.filters).then(function successCallback(response) {

                if (response.status == 200) {
                    
                    //if ( $scope.filters.page === undefined ||  $scope.fitlers.page == 1 ){
                    if ( response.data.data.carousells && response.data.data.carousells.length > 0){
                        $scope.carousell_slides = response.data.data.carousells[0];
                    }
                    //}

                    $scope.properties_limits = [];
                    if (response.data.message !== '') {
                        $scope.products = response.data.data.data.map(function (a) {
                            var slug = a.title.toLowerCase().replace(/[,;\-\_\/\\\.]/g,"").replace(/\_{2,}/,'_').replace(/\s/g,"_");
                            a.fixed_url = '/publicacion/'+slug+"-"+a.id;
                            if(a.is_external_url == '1'){
                                var external_url = a.external_url.replace('https:','http:');
                                a.imgurl = external_url;
                            }else{
                                a.imgurl = baseUrl + '/images/publication/' + a.id + '/main.jpeg';
                            }

                            a.hide_cart = (a.publication_type == "0" || $scope.invalidCartCategories.indexOf(parseInt(a.category_id)) > 0 ) ? 1 : 0;
                            return a;
                        });
                        $scope.objPage = response.data.data;
                        delete ($scope.objPage.data);
                        $scope.lastPage = response.data.data.last_page;
                        $scope.pageTotal = response.data.data.total;
                        $scope.sideFilters.categories = response.data.data.categories;
                        $scope.sideFilters.brands = response.data.data.brands;
                        //avoid loading
                        if ($scope.filters.category_id > 0 && $scope.filters.subcategory_id > 0 ){
                            $scope.sideFilters.properties = response.data.data.properties;

                            Object.keys($scope.sideFilters.properties).forEach(function(a){
                                $scope.sideFilters.properties[a].model_name = $scope.sideFilters.properties[a].type+"_"+$scope.sideFilters.properties[a].id;
                                $scope.sideFilters.properties[a].name = a;
                                if( $scope.sideFilters.properties[a].values.length > 5 ){
                                    $scope.properties_limits[a] = 5;
                                }else{
                                    $scope.properties_limits[a] = $scope.sideFilters.properties[a].values.length;
                                }

                            });

                        }else{
                            $scope.sideFilters.properties = [];
                        }

                        $scope.slider.options.floor = parseInt(response.data.data.price_ranges.min);
                        $scope.slider.options.ceil = parseInt(response.data.data.price_ranges.max);
                        $scope.USD_2_MXN = response.data.data.USD_2_MXN;
                        if($scope.filters.category_id  > 0 ){
                            //map the category
                            $scope.sideFilters.categories.map(function(a){
                                if(a.id == $scope.filters.category_id){
                                    $scope.category_name = a.title;
                                }
                            });
                        }

                        if ( $scope.sorting === undefined ){
                            $scope.sorting = { id : $scope.filters.order , label : $scope.order_labels[$scope.filters.order] };
                        }
                        //load the categories
                        var old = $location.hash();
                        $location.hash('results');
                        $anchorScroll();
                        $location.hash(old);

                    }
                }//status 200 is ok

                if (response.status == 204) {
                    $scope.products = [];  //no content
                }
            }, function errorCallback(response) {
                if (response.status == 404 || response.status == 500) {
                    $scope.products = [];
                }
            });
        }
    });

}]);
