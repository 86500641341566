myApp.controller('productController', [
    '$scope',
    'productModel',
    '$routeParams',
    'cartModel',
    '$location',
    'categoriesModel',
    'storeModel',
    'locationModel',
    '$anchorScroll',
    'Upload',
    '$controller',
    '$timeout',
    'wishListService',
    '$uibModal',
    'propertiesModel',
    'brandsModel',
    'userModel',
    function (
            $scope,
            productModel,
            $routeParams,
            cartModel,
            $location,
            categoriesModel,
            storeModel,
            locationModel,
            $anchorScroll,
            Upload,
            $controller,
            $timeout,
            wishListService,
            $uibModal,
            propertiesModel,
            brandsModel,
            userModel) {
        $scope.address = {};
        $scope.product = {};
        $scope.contact_form = {};
        $scope.store = {};
        $scope.product = {};
        $scope.errors = {};
        $scope.errors.messageResponse = {};
        $scope.quantity = 1;
        $scope.quantityOpts = [{ label : "1", id : "1"},{ label : "2" , id : "2" },{label : "3" , id : "3" } ];
        $scope.imagesToAdd = 0;
        $scope.wishListMessages = {};
        $scope.showModal = false;
        $scope.sliderActive = 0;//index 0 by default
        $scope.currency_opts = [{ id : 'MXN' , 'name': 'Pesos' },{ id : 'USD', 'name':'Dolares' }];
        $scope.tmpImages = {};
        $scope.productPropertiesValues = [];
        $scope.user = {};
        $scope.review_pending_ads = false;
        $scope.message  = ''
        $scope.sending = false;
        $scope.ct_exist = false;

        $scope.contact = {
            name: '',
            email: '',
            question: ''
        };
        
        var $ctrl = this;

        $scope.sendContact = function(product_id){
            $scope.sending = true
            $scope.message  = ''
            var obj = {
                name: $scope.contact.name,
                email: $scope.contact.email,
                question: $scope.contact.question,
                publication_id: product_id
            }
            $scope.errors = {};
    
            Upload.upload({
                url: baseUrl + '/sendmessage',
                headers: {"Content-Type": "application/json"},
                method: 'POST',
                data: obj
            }).then(function (resp) {
                
                if(resp.status == 200){
                    $scope.contact.name = ''
                    $scope.contact.email = ''
                    $scope.contact.question = ''
                    $scope.message = 'Mensaje enviado'
                    $scope.sending = false;
                }
                }, function (resp) {
                    if(resp.status == 412){
                        var errors = resp.data.data;
                        $scope.sending = false;
    
                     if (errors.hasOwnProperty('name') ){ $scope.errors.name = {'required':true} }
                     if (errors.hasOwnProperty('email') ){ $scope.errors.email = {'required':true} }
                     if (errors.hasOwnProperty('question') ){ $scope.errors.question = {'required':true} }
                    }
    
            });
        }

        $scope.addToCart = function (id) {
            if ($scope.quantity.id !== '0') {

                if (id === undefined) {
                    id = $routeParams.product_id
                }
                //tomamos la lista de localstorage
                var itemsList = [];
                cartModel.getCartList().then(
                    response => {
                        itemsList = response.data
                        //concatenar ids
                        var concatenado = "";
                        var exists = false;

                        var newList = [];
                        angular.forEach(itemsList, function (val, key) {
                            concatenado = concatenado + ',' + val.productId;
                            if (val.id == id) {
                                exists = true;
                                //val[key].quantity++;
                                console.log("Producto en el carrito");
                            }
                        });

                        if (!exists) {
                            concatenado = concatenado + ',' + id;

                            var img = ( $scope.product.images.length > 0 )   ? $scope.product.images[0].url : "";

                            itemsList.push({
                                id: $scope.product.id,
                                price: $scope.product.MXN_price,
                                price_USD : $scope.product.USD_price,
                                picture: img,
                                title: $scope.product.title,
                                quantity: parseInt($scope.quantity.id),
                                store_id : $scope.product.store.id,
                                store_name : $scope.product.store.name,
                                external_key : $scope.product.external_key,
                                currency_code : $scope.product.currency_code,
                                store_country_id : $scope.product.store.country_id,
                                store_state_id : $scope.product.store.state_id,
                                store_city_id : $scope.product.store.city_id,
                            });
                        }

                        cartModel.setCartList(itemsList);
                        $location.path('/cart');
                    }
                )
            }

        };

        $scope.updateCurrency = function(currency){
            $scope.product.currency = currency;
        }

        $scope.back = function (){
            window.history.back();
        }

        $scope.addToWishList =  function(id){
            $scope.wishListMessages = {added:false};
            $scope.wishListMessages = {repeated:false};
            $scope.wishListMessages = {error:false};

             wishListService.add(id).then(function successCallback(response){
                if(response.status == 201){
                    $scope.wishListMessages = {added:true};
                }

                if(response.status == 200){
                    $scope.wishListMessages = {repeated:true};
                }
             },function errorCallback(response){
                $scope.wishListMessages = {error:true};
             });
        };

        $scope.createObjToAdd = function () {
            $scope.itemToAdd = {
                productId: $routeParams.product_id,
                quantity: $scope.quantity,
                productObj: $scope.product
            };
        };

        $scope.initProduct = function () {

            $scope.quantity = '0';
            $scope.product = {};
            $scope.itemToAdd = {
                productId: $routeParams.product_id,
                quantity: $scope.quantity,
                productObj: $scope.product
            };
        };

        $scope.initProduct();

        angular.extend($scope, productModel, $location, $routeParams,$uibModal,
            $controller('gmapController',{$scope:$scope}),propertiesModel,userModel,{

            getBrand : function(val){

                return brandsModel.get({name:val}).then( function successCallback(response){
                        return response.data.data.map(function(value){
                            console.log(value);
                        //return value.name;
                        return { name  : value.name,  id : value.id };
                    });
                });
            },
            getCountry : function(val){
                return locationModel.getCountry(val).then( function successCallback(response){
                    return response.data.map(function(value){
                        //return value.name;
                        return { name  : value.name,  id : value.id };
                    });
                });
            },

            getState : function(val){
                return locationModel.getState($scope.product.country_id, val ).then(function successCallback(response){
                    return response.data.map(function(value){
                    //return value.name;
                    return { name  : value.name,  id : value.id };
                    });
                });
            },
            getCities : function( val ){
                return locationModel.getCity($scope.product.state_id, val).then( function successCallback(response){
                    return response.data.map(function(value){
                        return { name  : value.name,  id : value.id };
                    });
                });
            },
            onSelectBrand : function($item,$model,$label){
                $scope.brand            = $item;
                $scope.product.brand_id = $item.id;
            },
            onSelect  : function($item,$model,$label){
                $scope.product.state = '';
                $scope.product.state_id = '';
                $scope.product.city = '';
                $scope.product.city_id = '';
                $scope.product.country_id = $item.id;
            },
            onSelectState : function($item,$model,$label){
                $scope.product.city = '';
                $scope.product.city_id = '';
                $scope.state = $item;
                $scope.product.state_id = $item.id;
            },
            onSelectCity : function($item , $model, $label){
                $scope.product.city_id = $item.id;
                $scope.city = $item;
                $scope.getMapFromLocation();
            },

            initCreateForm: function (type) {

                productModel.getLastAd().then(function successCallback(response){
                    if(response.status == 200){
                        $scope.contact_form.contact_email = response.data.data.contact_email
                        $scope.contact_form.contact_phone = response.data.data.contact_phone
                        $scope.contact_form.contact_cellphone = response.data.data.contact_cellphone
                    }
                }, function errorCallback(response){
    
                });

                locationModel.getDefaultLocation(type).then(function successCallback(response){
                    if(response.status == 200){

                        $scope.product.country = response.data.data.country;
                        $scope.product.state = response.data.data.state;
                        $scope.product.city = response.data.data.city;
                        $scope.state = $scope.product.state;
                        $scope.city = $scope.product.city;
                        $scope.product.country_id = response.data.data.country.id;
                        $scope.product.state_id = response.data.data.state.id;
                        $scope.product.city_id = response.data.data.city.id;

                    }
                });

                categoriesModel.getCategories().then(function successCallback(response) {
                    $scope.categories = response.data.data;
                });

                userModel.userHasStore().then(function successCallback(response){
                    if(response.status == 200){
                        $scope.user.hasStore = response.data.data.has_store == "1" ? true: false;
                        $scope.user.name = userModel.getSavedUser();
                        $scope.user.id = response.data.data.user_id;
                        $scope.store.name = response.data.data.store_name;
                        $scope.user.type  = response.data.data.type;
                        $scope.user.store_status = response.data.data.store_status;
    
                    }
                }, function errorCallback(response){
    
                });
            },
            createAd : function(){
                $scope.errors = {};

                //$scope.product.images = $scope.images;
                if(!$scope.product.show_exact_location){
                    $scope.product.latitude = null;
                    $scope.product.longitude = null;
                }else{
                    $scope.product.latitude = $scope.map.marker.coords.latitude;
                    $scope.product.longitude = $scope.map.marker.coords.longitude;
                    $scope.product.street = $scope.address.street;
                }
                $scope.product.show_exact_location = ($scope.product.show_exact_location) ? 1 : 0;

                //if we have properties post them
                var tmpPVs = [];
                if ( Object.keys($scope.productPropertiesValues).length > 0  ){

                    Object.keys($scope.productPropertiesValues).map(function(key,index){

                        if ( $scope.productPropertiesValues[key] == true ){
                            tmpPVs.push(key);
                        }
                    });

                    $scope.product.properties_values = tmpPVs;
                }

                if($scope.contact_form.contact_email){
                    $scope.product.contact_email = $scope.contact_form.contact_email;
                }
                if($scope.contact_form.contact_phone){
                    $scope.product.contact_phone = $scope.contact_form.contact_phone;
                }
                if($scope.contact_form.contact_cellphone){
                    $scope.product.contact_cellphone = $scope.contact_form.contact_cellphone;
                }

                var produtObj = {
                    name: $scope.product.name,
                    price: $scope.product.price,
                    description: $scope.product.description,
                    technical_info: $scope.product.technical_info,
                    images: $scope.product.images,
                    category_id: $scope.product.category.id,
                    subcategory_id: $scope.product.subcategory.id,
                    country_id: $scope.product.country_id,
                    state_id: $scope.product.state_id,
                    city_id: $scope.product.city_id,
                    publication_ad : 1,
                    default_image : $scope.product.default_image,
                    show_exact_location : $scope.product.show_exact_location,
                    street : $scope.address.street,
                    properties_values : tmpPVs,
                    brand_id : $scope.product.brand_id,
                    contact_email: $scope.product.contact_email,
                    contact_phone: $scope.product.contact_phone,
                    contact_cellphone: $scope.product.contact_cellphone
                };

                Upload.upload({
                 url: baseUrl + '/publication',
                 headers: {"Content-Type": "application/json"},
                 method: 'POST',
                 data: produtObj
                }).then(function (resp) {
                    console.log(resp);
                    if(resp.status == 200){
                        if($scope.user.type == 9){
                            $location.path('/user/dashboard/admin/anuncios');
                        }else{
                            $location.path('/user/dashboard/anuncios');
                        }
                    }
                 }, function (resp) {
                     if(resp.status == 412){
                         var errors = resp.data.data;

                        if (errors.hasOwnProperty('name') ){ $scope.errors.name = {'required':true} }
                        if (errors.hasOwnProperty('price') ){ $scope.errors.price = {'format':true} }
                        if (errors.hasOwnProperty('description') ){ $scope.errors.description = {'required':true} }
                        if (errors.hasOwnProperty('category_id') ){ $scope.errors.category = {'required':true} }
                        if (errors.hasOwnProperty('subcategory_id') ){ $scope.errors.subcategory = {'required':true} }
                        if (errors.hasOwnProperty('images') ){ $scope.errors.images = {'required':true} }
                        if (errors.hasOwnProperty('contact_email') ){ $scope.errors.contact_email = {'format':true} }
                        if (errors.hasOwnProperty('contact_phone') ){ $scope.errors.contact_phone = {'format':true} }
                        if (errors.hasOwnProperty('contact_cellphone') ){ $scope.errors.contact_cellphone = {'format':true} }
                        
                 
                        var old = $location.hash();
                        $location.hash('mainContent');
                        $anchorScroll();
                        $location.hash(old);
                     }

                 }, function (evt) {
                 var progressPercentage = parseInt(100.0 * evt.loaded / evt.total);
                    console.log('progress: ' + progressPercentage + '% ');
                });

            },
            createProduct: function () {

                if($scope.map.marker == undefined ){
                    $scope.map.marker= {};
                    $scope.map.marker.coords = {};
                    $scope.map.marker.coords.latitude = "";
                    $scope.map.marker.coords.longitude = "";
                }

                $scope.product.show_exact_location = ($scope.product.show_exact_location) ? 1 : 0;

                //if we have properties post them
                var tmpPVs = [];
                if ( Object.keys($scope.productPropertiesValues).length > 0  ){

                    Object.keys($scope.productPropertiesValues).map(function(key,index){

                        if ( $scope.productPropertiesValues[key] == true ){
                            tmpPVs.push(key);
                        }
                    });

                    $scope.product.properties_values = tmpPVs;
                }

                var produtObj = {
                    name: $scope.product.name,
                    price: $scope.product.price,
                    description: $scope.product.description,
                    technical_info: $scope.product.technical_info,
                    images: $scope.product.images,
                    category_id: $scope.product.category.id,
                    country_id : $scope.product.country_id,
                    subcategory_id : $scope.product.subcategory.id,
                    state_id : $scope.product.state_id,
                    city_id : $scope.product.city_id,
                    default_image : $scope.product.default_image,
                    latitude : $scope.map.marker.coords.latitude,
                    longitude : $scope.map.marker.coords.longitude,
                    street : $scope.address.street,
                    show_exact_location : $scope.product.show_exact_location,
                    properties_values : tmpPVs,
                    brand_id : $scope.product.brand_id,
                    utility : $scope.product.utility,
                    iva : $scope.product.iva,
                };

                $scope.errors = {};

                Upload.upload({
                 url: baseUrl + '/publication',
                 headers: {"Content-Type": "application/json"},
                 method: 'POST',
                 data: produtObj
                 }).then(function (resp) {
                    if(resp.status == 200){
                        if($scope.user.type == 9){
                            $location.path('/user/dashboard/productos');
                        }else{
                            $location.path('/user/dashboard/tienda/productos');
                        }
                    }
                 }, function (resp) {
                    if(resp.status == 412){
                         var errors = resp.data.data;

                        if (errors.hasOwnProperty('name') ){ $scope.errors.name = {'required':true} }
                        if (errors.hasOwnProperty('price') ){ $scope.errors.price = {'format':true} }
                        if (errors.hasOwnProperty('description') ){ $scope.errors.description = {'required':true} }
                        if (errors.hasOwnProperty('category_id') ){ $scope.errors.category = {'required':true} }
                        if (errors.hasOwnProperty('subcategory_id') ){ $scope.errors.subcategory = {'required':true} }
                        if (errors.hasOwnProperty('utility') ){ $scope.errors.utility = {'format':true} }
                        if (errors.hasOwnProperty('iva') ){ $scope.errors.iva = {'format':true} }
                        var old = $location.hash();
                        $location.hash('mainContent');
                        $anchorScroll();
                        $location.hash(old);
                     }
                 }, function (evt) {
                    var progressPercentage = parseInt(100.0 * evt.loaded / evt.total);
                    console.log('progress: ' + progressPercentage + '% ');
                });
            },
            initEdit: function () {
                //HEREHERE

                userModel.getUser().then(function successCallback(user_response){
                    if ( user_response.status == 200 ){

                        $scope.user = user_response.data.data;

                        var product_id = $routeParams.product_id;
                        $scope.sending = false;

                        productModel.getProduct(product_id,{ get_categories_info :1 }).then(function successCallback(response) {
                            if (response.status == 200) {
                                $scope.categories = response.data.data.categories;
                                $scope.subcategories = response.data.data.subcategories;
                                $scope.product = response.data.data;
                                $scope.state = $scope.product.state;
                                $scope.city  = $scope.product.city;
                                $scope.brand = $scope.product.brand;
                                $scope.address.street = $scope.product.street;
                                $scope.remaining_images = (4 -  $scope.product.images.length);
                                
                            
                                $scope.show_exact_location = $scope.product.show_exact_location;
                                $scope.product.price = parseInt($scope.product.price);
                                var data  = {
                                    category_id     : $scope.product.category_id ,
                                    subcategory_id  : $scope.product.subcategory_id,
                                    include_values  : 1 ,
                                };
                                propertiesModel.getCategoryProperties(data).then(function successCallback(response){
                                    if(response.status == 200 ){
                                        $scope.properties_values = response.data.data;
                                        $scope.properties_values.length = Object.keys(response.data.data).length;
                                        //now map the values of properties_values
                                        $scope.product.properties.map(function(a){
                                            var key = a.pp_id+"_"+a.pv_id;
                                            $scope.productPropertiesValues[key] = true;
                                        });

                                    }
                                },function errorCallback(response){
                                    if (response.status == 404){

                                    }
                                });

                                if($scope.show_exact_location == "1"){
                                    $scope.show_exact_location = true;
                                    $timeout(function(){
                                        center = { latitude: $scope.product.latitude, longitude:$scope.product.longitude };
                                        $scope.initMap(center,16,{ coords : Object.create(center) });
                                    });
                                }
                            }
                            if ( $scope.user.type == 9 && $routeParams.review_pending_ads == 1  ){
                                $scope.getNextAd();
                            }


                            $scope.getSubcategories($scope.product.category_id);
                        });
                    }
                });

            },
            getNextAd : function(){
                productModel.getNextAdToReview().then(function(response){
                    if ( response.status == 200 ){
                        var ad = response.data.data;
                        $scope.review_pending_ads = true;
                        $scope.next_url = '/user/dashboard/anuncios/editar/'+ad.id+'?review_pending_ads=1';
                    }else{
                        $scope.review_pending_ads = false;
                    }
                },function(response){
                    $scope.review_pending_ads = false;
                });
            },
            nextPost : function(){
                if ($scope.next_url !== undefined && $scope.next_url.length > 0  ){
                    $location.search({});
                    $location.path($scope.next_url);
                }
            },
            activateAndEdit : function(){

            },
            saveAndActivate : function (editProduct){
                $scope.product.status = 1;
                $scope.product.activate_publication = 1;
                $scope.edit(editProduct);
            },
            edit: function (editProduct) {

                $scope.sending = true;
                $scope.tmpImages = Object.create($scope.product.images);
                $scope.product.images = $scope.images;
                $scope.errors = {};
                $scope.product.show_exact_location = ($scope.product.show_exact_location == '1') ? 1 : 0;
                $scope.product.category_id = $scope.product.category.id;
                $scope.product.subcategory_id = $scope.product.subcategory.id;
                if(  $scope.product.show_exact_location == false  ){
                    $scope.product.latitude = null;
                    $scope.product.longitude = null;
                }else{
                    $scope.product.latitude = $scope.map.marker.coords.latitude;
                    $scope.product.longitude = $scope.map.marker.coords.longitude;
                    $scope.product.street = $scope.address.street;
                }

                //if we have properties post them
                var tmpPVs = [];
                if ( Object.keys($scope.productPropertiesValues).length > 0  ){

                    Object.keys($scope.productPropertiesValues).map(function(key,index){

                        if ( $scope.productPropertiesValues[key] == true ){
                            tmpPVs.push(key);
                        }
                    });

                    $scope.product.properties_values = tmpPVs;
                }

                $scope.product._method = 'PUT';

                Upload.upload({
                 url: baseUrl + '/publication/'+$scope.product.id,
                 headers: {"Content-Type": "application/json"},
                 method: 'POST',
                 data: $scope.product,
                }).then(function (response) {
                    $scope.sending = false;
                    if(response.status == 200){
                        $scope.errors.messageResponse = {validationErrors: false};
                        $scope.errors.messageResponse = {success: true};
                        var old = $location.hash();
                        $location.hash('mainContent');
                        $anchorScroll();
                        $location.hash(old);
                        $scope.images = null;
                        $scope.initEdit();
                    }

                    if (response.status == 204) {
                        console.log("No content");
                    }
                 }, function (response) {
                    $scope.sending = false;

                    var errors = response.data.data;
                    if (response.status == 412) {

                        if (errors.hasOwnProperty('title') ){ $scope.errors.title = {'required':true} }
                        if (errors.hasOwnProperty('price') ){ $scope.errors.price = {'format':true} }
                        if (errors.hasOwnProperty('description') ){ $scope.errors.description = {'required':true} }
                        if (errors.hasOwnProperty('category_id') ){ $scope.errors.category = {'required':true} }
                        if (errors.hasOwnProperty('subcategory_id') ){ $scope.errors.subcategory = {'required':true} }
                        if (errors.hasOwnProperty('utility') ){ $scope.errors.utility = {'format':true} }
                        if (errors.hasOwnProperty('iva') ){ $scope.errors.iva = {'format':true} }

                        if($scope.product.images !== undefined ){
                            $scope.images = Object.create($scope.product.images);
                        }

                        if($scope.tmpImages !== undefined){
                            $scope.product.images = Object.create($scope.tmpImages);
                        }


                        $scope.tmpImages = null;

                        $scope.errors.messageResponse = {validationErrors: true};
                        $scope.errors.messageResponse = {success: false};
                        var old = $location.hash();
                        $location.hash('mainContent');
                        $anchorScroll();
                        $location.hash(old);
                        return;
                    }
                 }, function (evt) {
                 var progressPercentage = parseInt(100.0 * evt.loaded / evt.total);
                    console.log('progress: ' + progressPercentage + '% ');
                });

            },
            deleteImg : function(image){

                var id = image.id;

                productModel.deleteImage(id).then(function successCallback(response){
                    if(response.status == 200){

                        var index = null;
                        $scope.product.images.map(function(a,b){
                            if(a.id == id ){
                                index = b;
                            }
                        });
                        if(index !== null ){
                            $scope.product.images.splice(index,1);
                        }

                        $scope.remaining_images++;

                    }else if(response.status == 204){

                    }
                }, function errorCallback(response){
                    if(response.status == 500){

                    }
                });

            },
            setDeleteImg : function(image,$event){
                $event.stopPropagation();

                $ctrl.items = {};
                $ctrl.items.imagetodelete = image;
                var size   = 'md';
                var modalInstance = $uibModal.open({
                  ariaLabelledBy: 'modal-title',
                  ariaDescribedBy: 'modal-body',
                  templateUrl: 'myModalContent.html',
                  controller : 'ModalInstanceCtrl',
                  controllerAs : '$ctrl',
                  size: size,
                  resolve: {
                    items: function () {
                      return $ctrl.items;
                    }
                  }
                });

                modalInstance.result.then(function (selectedImage) {
                    console.log(selectedImage)
                    $scope.deleteImg(selectedImage);
                }, function () {
                    //closed modal
                    console.log('Modal dismissed at: ' + new Date());
                });

            },
            closeDeleteImgModal : function(){
                $scope.imageToDelete = {};
                $scope.showModal = false;
            },
            getProduct: function () {

                var product_id = $routeParams.product_id.split('-')[1];

                productModel.getProduct(product_id).then(function successCallback(response) {
                    $scope.product = response.data.data;
                    
                    if ($scope.product.stock_ct != '0'){
                        if ($scope.product.stock_ct.info.existencia){
                            $scope.ct_exist = true;
                            $scope.quantityOpts = []
                            for( var i = 1; i <= $scope.product.stock_ct.info.existencia; i++ ){
                                $scope.quantityOpts.push({
                                  label:i.toString() , 
                                  id:i.toString()  
                                });  
                            }
                            console.log($scope.quantityOpts)
                        }

                    }
                    var whatsapp_url_desktop = 'https://web.whatsapp.com/send?l=es&phone=';
                    var whatsapp_url_phone = 'https://api.whatsapp.com/send?l=es&phone=';
                    var current_url = window.location.href;

                    $scope.product.contact_info.whatsapp_url_desktop = whatsapp_url_desktop + "52"+
                        $scope.product.contact_info.whatsapp +"&text=Hola he visto tu publicacion en webstores.vendobara.com y quisiera saber mas sobre tu producto "+current_url;

                        $scope.product.contact_info.whatsapp_url_phone = whatsapp_url_phone + "52"+
                            $scope.product.contact_info.whatsapp +"&text=Hola he visto tu publicacion en webstores.vendobara.com y quisiera saber mas sobre tu producto "+current_url;

                    if ( $scope.product.images.length > 0 ){
                        //map the images
                        var hasDefaultImage = false;

                        $scope.product.images.map(function(a,b){
                            /*if(a.url.length > 0 ){
                              $scope.product.images.spice(b,1);
                              return;
                            }*/
                            a.url = a.url.replace('https:','http:');
                            a.external_url = a.external_url.replace('https:','http:');
                            if(a.default == "1"){
                                $scope.sliderActive = b;
                                hasDefaultImage = true;
                                return;
                            }
                        });

                        $scope.enableAddToCart = ( $scope.product.stock > 0 ) ? true : false;

                        //if there is not default image set the 0 index as default
                        if(!hasDefaultImage){
                            $scope.sliderActive = 0;
                        }
                    }
                    storeModel.getStore($scope.product.store_id).then(function successCallback(response) {
                        $scope.store = response;
                    });
                    if($scope.product.currency_code == 'MXN'){
                        $scope.product.currency = {id : 'MXN', 'name': 'Pesos'};
                    }else{
                        $scope.product.currency = {id : 'USD', 'name': 'Dolares'};
                    }

                    $scope.createObjToAdd();
                }, function errorCallback() {

                });
            },
            back: function(){
                window.history.back();
            },
            checkInitMap : function(first){

                if($scope.product.show_exact_location && first){
                 $scope.getMapFromLocation();
                 return;
                }

                if($scope.show_exact_location && (!$scope.product.latitude && !$scope.product.longitude )){

                    $scope.getMapFromLocation();
                    return;
                }
            },
            imageCount : function(){

                var total = $scope.product.images.length + $scope.images.length;
                var left = 4 - total;
                left = (left < 0 ) ? 0 : left;

                $scope.remaining_images = left;
            },
            getProperties : function(cid,scid){

                var data = { category_id : cid , subcategory_id : scid, include_values : 1 };
                propertiesModel.getCategoryProperties(data).then(function successCallback(response){
                    if(response.status == 200 ){
                        $scope.properties_values = response.data.data;
                        $scope.properties_values.length = Object.keys(response.data.data);
                    }
                },function errorCallback(response){
                    if (response.status == 404){

                    }
                });
            },
            getSubcategories : function(cat_id){

                categoriesModel.getCategories({ category_id : cat_id }).then(function successCallback(response){
                    if(response.status == 200){
                        $scope.subcategories = response.data.data;
                        var tmp = $scope.product.category_id;
                        $scope.product.subcategory_id = 0;
                        $scope.product.subcategory_id = tmp;

                    }
                });
            },
            setProductSlide : function(index){
                $scope.sliderActive = index;
            }
        });//end extend
    }]);

angular.module('myApp').controller('ModalInstanceCtrl', function ($scope,$uibModalInstance, items) {
  var $ctrl = this;

  $ctrl.items = items;

  $ctrl.ok = function () {
    $uibModalInstance.close($ctrl.items.imagetodelete);
  };

  $ctrl.cancel = function () {
    $uibModalInstance.dismiss('cancel');
  };
});
