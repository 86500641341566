myApp.controller('manageFiltersCategoriesController', [
    '$scope',
    '$routeParams',
    'categoriesModel',
    '$anchorScroll',
    'propertiesModel',
    function (
            $scope,
            $routeParams,
            categoriesModel,
            $anchorScroll,
            propertiesModel) {
        $scope.categories;
        $scope.invalidCartCategories;
        $scope.cache = {};
        $scope.cache.subcategories = {};
        $scope.messages = {};
        $scope.properties = [];
        $scope.property_status = {};
        $scope.property_values_status = {};
        $scope.related_properties_category = {};
        $scope.related_status = {};
        $scope.type;
        $scope.properties_type = ['CHECKBOX','RANGE','SELECT'];

        angular.extend($scope,categoriesModel,propertiesModel,{
            init : function(){

                categoriesModel.getCategories({category_id : $scope.category_id}).then(function successCallback(response){
                    $scope.parentCategories = response.data.data;
                });

                $scope.reloadProperties();

            },
            reloadProperties : function(){
                propertiesModel.get({all : 1 }).then(function successCallback(response){
                    if ( response.status == 200 ) {

                        if ( typeof response.data.data == 'object'){
                            response.data.data.map(function(a){
                                a.name = a.name.replace(/\_/g,' ');
                                $scope.properties.push(a);
                            })
                        }
                    }
                });
            },
            setSubcategory  : function(){
                //get the filters related to this specific category-subcategory
                //$scope.subcategory_id = $scope.subcategory_id.id;
                var category_id = $scope.parent_category_id.id;
                var subcategory_id = $scope.subcategory_id.id;
                var data = { category_id : category_id , subcategory_id : subcategory_id};
                propertiesModel.getCategoryProperties(data).then(function successCallback(response){
                    if(response.status == 200 ){
                        $scope.propertyValues = [];
                        $scope.categoryProperties = response.data.data;
                        $scope.related_properties_category._404 = false;
                    }
                },function errorCallback(response){
                    if (response.status == 404){
                        $scope.related_properties_category._404 = true;
                    }
                });

            },
            getSubCategories : function(){
                var category_id = $scope.parent_category_id.id;
                var data = { category_id : category_id };
                //$scope.category = $scope.parent_category_id;

                if($scope.cache.subcategories[category_id] !== undefined ){
                    //console.log('already cached');
                    $scope.subCategories = $scope.cache.subcategories[category_id];
                    return;
                }

                categoriesModel.getCategories(data).then(function successCallback(response){
                    if(response.status == 200){

                        $scope.cache.subcategories[category_id] = response.data.data;
                        $scope.subCategories = response.data.data;

                    }
                    if(response.status == 204){
                        $scope.subCategories  = {};
                    }
                }, function errorCallback(response){
                    $scope.subCategories = {};
                });
            },
            addProperty : function(){

                    var name =  $scope.property_name;
                    var display_name = $scope.property_display_name;
                    var type = $scope.type;
                    var data = {
                        name : name ,
                        display_name : display_name,
                        type : type,
                    };

                    if (data.type == 'RANGE'){
                        data.measure_unit = $scope.measure_unit;
                    }

                    propertiesModel.add(data).then(function successCallback(response){
                        if(response.status == 201){
                            $scope.property_status.success = true;
                            $scope.property_status.exists  = false;
                            $scope.property_name = '';
                            //reload
                        }else if(response.status == 200 ){
                            $scope.property_status.exists = true;
                            $scope.property_status.success = false;
                        }
                        if (response.status == 200 || response.status == 201){
                            $scope.filterPropertyQuery = '';
                            $scope.reloadProperties();
                        }

                    });
            },
            editProperty : function(){

                var data = {
                    id : $scope.property_id.id,
                    name : $scope.edit_property_name,
                    display_name : $scope.edit_property_display_name,
                    type : $scope.edit_type,
                 };

                if (data.type == 'RANGE'){
                    data.measure_unit = $scope.edit_measure_unit;
                }
                propertiesModel.update(data).then(function successCallback(response){
                    if(response.status == 200){
                        $scope.property_status.updated = true;
                        $scope.property_status.exists = false;
                        $scope.property_status.success = false;
                        $scope.reloadProperties();
                    }
                });
            },
            addPropertyValue : function(){
                var name =  $scope.property_value_name;
                var data = {
                    property_id : $scope.property_id.id,
                    name : name ,
                };
                propertiesModel.addPropertyValue(data).then(function successCallback(response){
                    $scope.property_values_status.error_n  = false;
                    if(response.status == 201){
                        $scope.property_values_status.success = true;
                        $scope.property_values_status.exists  = false;
                        $scope.property_values_status.updated = false;
                        //reload
                        $scope.property_value_name = '';
                    }else if(response.status == 200 ){
                        $scope.property_values_status.exists  = true;
                        $scope.property_values_status.success = false;
                        $scope.property_values_status.updated = false;
                    }
                    $scope.getPropertyValues();

                }, function errorCallback(response){

                    if(response.status == 412){
                        $scope.property_values_status.exists  = false;
                        $scope.property_values_status.success = false;
                        $scope.property_values_status.updated = false;
                        $scope.property_values_status.error_n  = true;
                    }

                });
            },
            setPropertyValue : function(){
                    $scope.edit_property_value_name = $scope.value_id.name;
            },
            editPropertyValue : function(){

                var data = {
                    property_id : $scope.property_id.id ,
                    name : $scope.edit_property_value_name,
                    prev_name : $scope.value_id.name,
                };

                propertiesModel.updatePropertyValue(data).then(function successCallback(response){
                    if(response.status == 200){
                        $scope.property_values_status.updated = true;
                        $scope.property_values_status.what  = false;
                        $scope.property_values_status.success = false;
                        $scope.getPropertyValues();
                    }
                });
            },
            getPropertyValues : function(){
                var data = { id : $scope.property_id.id };
                $scope.edit_property_name = $scope.property_id.name;
                $scope.edit_property_display_name = $scope.property_id.display_name;
                $scope.edit_type = $scope.property_id.type;
                $scope.edit_measure_unit = $scope.property_id.measure_unit;

                propertiesModel.getPropertyValues(data).then(function successCallback(response){
                    if(response.status == 200){
                        $scope.propertyValues = response.data.data;
                        $scope.propertyValues.map(function(a){
                            a.name = a.name.replace(/\_/g,' ');
                            return a;
                        });
                    }
                });
            },
            generateRelation : function (){
                var data = {
                    property_id     : $scope.property_to_relate.id,
                    category_id     : $scope.parent_category_id.id,
                    subcategory_id  : $scope.subcategory_id.id,
                };

                propertiesModel.generateCategoryPropertyRelation(data).then(function successCallback(response){

                    if(response.status == 200 ){

                        $scope.related_status.invalid  = false;
                        $scope.related_status.success = false;
                        $scope.related_status.exists  = true;
                    }else if (response.status == 201){

                        $scope.related_status.invalid   = false;
                        $scope.related_status.success = true;
                        $scope.related_status.exists  = false;
                        //reload the properties in the cateogory-subcategory
                        $scope.setSubcategory();
                    }
                },function errorCallback(response){
                    if(response.status == 400 ){
                        $scope.related_status.invalid  = true;
                        $scope.related_status.success = false;
                        $scope.related_status.exists  = false;
                    }
                })
            },
        });
    }
]);
