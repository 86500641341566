myApp.controller('messagesController', [
                                    '$scope', 
                                    'productModel', 
                                    '$routeParams', 
                                    'cartModel', 
                                    '$location',
                                    'categoriesModel', 
                                    function (
                                            $scope, 
                                            productModel, 
                                            $routeParams, 
                                            cartModel, 
                                            $location,
                                            categoriesModel) {


    $scope.showThread = function (thread_id) {
        $location.path('user/dashboard/mensajes/conversacion/'+thread_id);
    };
}]);
