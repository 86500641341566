myApp.controller('manageCategoriesController', [
    '$scope',
    '$routeParams',
    'categoriesModel',    
    '$anchorScroll',
    function (
            $scope,
            $routeParams,            
            categoriesModel,                        
            $anchorScroll) {
        $scope.categories;
        $scope.invalidCartCategories;        
        $scope.cache = {};
        $scope.cache.subcategories = {};
        $scope.messages = {};

        angular.extend($scope,categoriesModel,{
            init : function(){
                categoriesModel.getCartInvalidCategories().then(function successCallback(response){
                    $scope.invalidCartCategories = response.data.data;
                });

                categoriesModel.getCartValidCategories().then(function successCallback(response){
                    $scope.categories = response.data.data;
                });

                categoriesModel.getCategories().then(function successCallback(response){
                    $scope.parentCategories = response.data.data;
                });

            },
            removeInvalidCartCategory : function(event){
                
                var elm = event.srcElement || event.target;
                var category_id = elm.getAttribute('data-id');
                categoriesModel.removeCartInvalidCategory({'category_id':category_id}).then(function successCallback(response){
                    if(response.status == 200 ){
                        $scope.init();
                    }
                   
                }, function errorCallback(response){

                });
            },

            addInvalidCartCategory : function(){
                var category_id = $scope.add_category_id;
                categoriesModel.addCartInvalidCategory({'category_id':category_id}).then(function successCallback(response){
                    $scope.init();
                }, function errorCallback(response){

                });
            },
            setSubcategory  : function(){
                
                $scope.subcategory = $scope.subcategory_id;

            },
            getSubCategories : function(){
                var category_id = $scope.parent_category_id.id;
                var data = { category_id : category_id };
                $scope.category = $scope.parent_category_id;

                if($scope.cache.subcategories[category_id] !== undefined ){
                    console.log('already cached');
                    $scope.subCategories = $scope.cache.subcategories[category_id];
                    return;
                }

                categoriesModel.getCategories(data).then(function successCallback(response){
                    if(response.status == 200){

                        $scope.cache.subcategories[category_id] = response.data.data;
                        $scope.subCategories = response.data.data;

                    }
                    if(response.status == 204){
                        $scope.suCtegories  = {};
                    }
                }, function errorCallback(response){
                    $scope.subCategories = {};
                });
            },
            createCategory : function(){
                var data = {category_name : $scope.category };
                categoriesModel.create(data).then(function successCallback(response){
                    if(response.status == 201){
                        $scope.category= '';
                        $scope.init();   
                    }
                }, function errorCallback(response){

                });
            },
            createSubcategory : function(){
                var data = {subcategory_name : $scope.new_subcategory, category_id : $scope.parent_category_id.id };
                categoriesModel.create(data).then(function successCallback(response){
                    if(response.status == 201){
                        $scope.init();   
                        $scope.new_subcategory = '';
                    }
                }, function errorCallback(response){

                });
            },
            editCategory : function(){
                
                var category = { id : $scope.category.id, name : $scope.category.title };
                categoriesModel.update(category).then(function successCallback(response){
                    if(response.status == 200){
                        $scope.messages.category_updated = {success: true};
                        $scope.category = {};
                        $scope.init();
                    }
                });
            },
            editSubcategory : function(){

                var subcategory = { id : $scope.subcategory.id , name :$scope.subcategory.title };
                categoriesModel.update(subcategory).then(function successCallback(response){
                    if(response.status == 200){
                        $scope.messages.subcategory_updated = {success: true};
                        $scope.subcategory = {};
                        $scope.init();
                    }
                });
            }
        });
    }
]);