myApp.controller( 'adminStoresController', [
                        '$scope',
                        'storeModel',
                        '$location',
                        '$routeParams',
                        function(
                                $scope,
                                storeModel,
                                $location,
                                $routeParams){
    $scope.store  = {};
    $scope.stores = null;
    $scope.filters = {};
    $scope.statuses = [{label:'Todas',id: 2 }, {label:'Activas', id : 1 },{label:'Desactivadas', id : 0},{label:'Pendientes', id: 3} ];
    $scope.errors = {};
    $scope.paginator = {};
    $scope.paginator.maxSize = 9;
    $scope.page = 1 ;
    $scope.hideAddProduct = true;

    angular.extend($scope,storeModel,$location, $routeParams,{
        init : function(){
            $scope.filters.status = 2;
            storeModel.getAdminStores($scope.filters).then(function succesCallback(response){
                if(response.status == 200){
                   $scope.stores = response.data.data.data;
                   $scope.stores.map(function(a){
                    a.imgurl = baseUrl + '/images/store/' + a.id;
                    return a;
                   });
                }
            });
        },
        toggleStatus : function(store){

            storeModel.toggleStatus(store.id,store.status).then(function succesCallback(response){
                if(response.status == 200){
                    //update the label
                }
            });
        },
        filter : function (){
            storeModel.getAdminStores($scope.filters).then(function succesCallback(response){
                if(response.status == 200){
                    $scope.stores = response.data.data.data;
                    $scope.stores.map(function(a){
                        a.imgurl = baseUrl + '/images/store/' + a.id;
                        return a;
                    });
                }
                if(response.status == 204){
                    $scope.stores = [];
                }
            });
        },
    });

    }]);
