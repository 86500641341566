myApp.controller( 'slidesCarousellController', ['$scope','slidesCarousellModel','slidesModel',
'$routeParams','Upload',function($scope,slidesCarousellModel,slidesModel,$routeParams,Upload){
	//csl = carousell slides list
	$scope.csl = [];
	$scope.messages = {};
	$scope.messages.errors = {};
	$scope.messages.slide = {};
	$scope.types = [
		{id:'HOMEPAGE',label: 'Pagina Inicio'},
		{id:'PARAMS',label: 'Parametros'},
	];
	$scope.categories 		= [];
	$scope.subcategories 	= [];

	$scope.filters = {};

	$scope.new_slides 	= [];
	$scope.slides 		= [];

	$scope.statuses = [
		{id : 0 , label : 'Inactivo'},
		{id : 1 , label : 'Activo'},
		{id : 2 , label : 'Todos'},
	];

	angular.extend($scope,slidesCarousellModel,slidesModel,$routeParams,Upload,{

		initSlidesCarousellList : function(){
			$scope.filters.active = {id : 1 , label : 'Activo'};
			var data  = { active : 1 };
			slidesCarousellModel.find(data).then(function successCallback(response){

				if ( response.status == 200 ){
					$scope.csl = response.data.data;

				}else if ( response.status == 204){
					$scope.csl = [];

				}

			},function errorCallback(response){
				if ( response.status == 402){

				}
			});
		},
		toggleStatus : function(object){
			console.log(object);
			var put_data = { active  : object.active };
			slidesCarousellModel.put(object.id,put_data).then(function successCallback(response){
				if(response.status == 200){
					$scope.messages.updated = true;
				}
			});

		},
		filter : function (){
			var data = $scope.filters;
			//set the id
			data.active = data.active.id;

			//want to get all
			if ( data.active == 2 ){
				delete data.active;
			}
			slidesCarousellModel.find(data).then(function successCallback(response){

				if ( response.status == 200 ){
					$scope.csl = response.data.data;

				}else if ( response.status == 204){
					$scope.csl = [];

				}

			},function errorCallback(response){
				if ( response.status == 402){

				}
			});
		},
		createSlideCarousell : function(form){
			var data = {
				name : $scope.carousell.name,
				action_type : $scope.carousell.action_type.id,
				category_id : $scope.carousell.category_id,
				subcategory_id : $scope.carousell.subcategory_id,
				params : $scope.carousell.params,
			};

			slidesCarousellModel.post(data).then(function successCallback(response){

				$scope.messages.errors = {};//clean all errors

				if (response.status == 201){
					$scope.messages.created = true;
				}else if ( response.status == 200 ){
					//well fuck
				}
			}, function errorCallback(response){
				if ( response.status == 412 ){
					if (response.data.data.errors.hasOwnProperty('name')) {
						$scope.messages.error = true;
						$scope.messages.action_type = false;
					}else if (response.data.data.errors.hasOwnProperty('action_type')) {
						$scope.messages.error = false;
						$scope.messages.action_type = true;
					}
				}
			});
		},
		initUpdateSlideCarousell : function(){
			var id = $routeParams.id;
			slidesCarousellModel.get(id).then(function(response){
					if(response.status == 200 ){

						$scope.carousell = response.data.data;
						$scope.carousell.type;
						$scope.carousell.params = response.data.data.url_params;
						$scope.types.map(function(a){
								if(a.id == $scope.carousell.action_type){
									$scope.carousell.type = a;
									return;
								}
						});
						$scope.loadSlides(id);
					}
			});
		},
		updateSlideCarousell : function(){

		},
		addSlide : function (){
			var slide = {
				name : $scope.slide.name,
				display_name : $scope.slide.display_name,
				description : $scope.slide.description,
				weight : $scope.slide.weight,
				destination_url : $scope.slide.destination_url,
				image : $scope.slide.file,
				slide_carousell_id : $routeParams.id,
			};

			Upload.upload({
				 url: baseUrl + '/slides',
				 headers: {"Content-Type": "application/json"},
				 method: 'POST',
				 data : slide,
			}).then(function (response) {
				 if (response.status == 201){
					 $scope.messages.slide.created = true;
					 $scope.loadSlides(slide.slide_carousell_id);
					 $scope.slide = {};

				 }else if ( response.status == 200 ){
					 //well fuck
				 }
			},function(response){
				 if ( response.status == 412 ){
					 if (response.data.data.errors.hasOwnProperty('name')) {

					 }else if (response.data.data.errors.hasOwnProperty('action_type')) {

					 }
				 }
			});

		},
		updateSlide : function (){

		},
		loadSlides : function(id){
			var params = {
				slides_carousell_id : id
			};
			slidesModel.find(params).then(function(response){
				if( response.status == 200 ){
					$scope.slides = response.data.data;
				}
			});
		}

	});//end extend
}]);
