myApp.controller('manageDepartmentController', [
    '$scope',
    'departmentModel',
    '$anchorScroll',
    '$location',
    'categoriesModel',
    '$routeParams',
    function(
        $scope,
        departmentModel,
        $anchorScroll,
        $location,
        categoriesModel,
        $routeParams
    ) {
        $scope.filters = {};
        $scope.statuses = [{
            id: "1",
            label: "Activo"
        }, {
            id: "0",
            label: "Inactivo"
        }];
        $scope.filters.status = 1;
        $scope.errors = {};
        $scope.submitDisabled = false;
        $scope.paginator = {};
        $scope.paginator.maxSize = 9;
        $scope.page = 1;
        $scope.cache = {};
        $scope.departmentCategories = {};
        $scope.selected_sbc = [];
        $scope.department_status = {};
        /**
         * @todo move the location to a service
         */
        angular.extend($scope, departmentModel, $anchorScroll, $location, categoriesModel, {
            filter: function() {

                $scope.getDepartments();

            },
            createDepartment: function() {

                var categories_ids = [];


                Object.keys($scope.departmentCategories).map(function(a, b) {

                    if ($scope.departmentCategories[a].subcategories !== undefined) {
                        $scope.departmentCategories[parseInt(a)].subcategories.map(function(x, y) {
                            categories_ids.push(x.id);
                        });
                    }
                });

                var department = {
                    name: $scope.department.name,
                    description: $scope.department.description,
                    categories: categories_ids,
                    image: $scope.file,
                };

                departmentModel.create(department).then(function successCallback(response) {
                    if (response.status == 201) {
                        $scope.submitDisabled = false;
                        $scope.department_status.success = true;
                        $scope.department_status.error   = false;
                        $scope.department_status.updated = false;

                        var old = $location.hash();
                        $location.hash('mainContent');
                        $anchorScroll();
                        $location.hash(old);
                        $location.path('/user/dashboard/departamentos');
                        

                    }
                }, function errorCallback(response) {
                    if (response.status == 412) {

                    }

                    if (response.status == 409) {

                    }

                });

            },
            initUpdate: function() {
                var id = $routeParams.department_id;

                categoriesModel.getCategories().then(
                    function successCallback(response) {
                        if (response.status == 200) {
                            $scope.categories = response.data.data;
                        }
                    });

                departmentModel.get(id).then(function successCallback(response) {
                    if (response.status == 200) {
                        $scope.department = response.data.data;
                        console.log($scope.department);
                        if ( $scope.department.image_file != ''){
                            $scope.department.image_url = baseUrl+"/images/department/"+$scope.department.image_file;
                        }else{
                            $scope.department.image_url = null;
                        }

                    }
                    if (response.status == 204) {

                    }

                });

            },
            update: function() {

                var department = {
                    _method: 'PUT',
                };


                departmentModel.update(department).then(function(response) {
                    if (response.status == 200) {

                    }
                    if (response.status == 204) {
                        $location.path('/');
                    }
                }, function(response) {
                    if (response.status == 400) {
                        $location.path('/');
                    }
                    if (response.status == 412) {

                    }
                });
            },
            initCreate: function() {
                categoriesModel.getCategories().then(
                    function successCallback(response) {
                        if (response.status == 200) {
                            $scope.categories = response.data.data;
                        }
                    }
                );
            },
            init: function() {
                $scope.getDepartments();
            },
            getDepartments: function() {
                //debugger;
                //$scope.filters = $routeParams;
                $scope.filters.limit = 10;
                $scope.filters.page = $scope.page === undefined ? 1 : $scope.page;

                departmentModel.find($scope.filters).then(function successCallback(response) {
                    if (response.status == 200) {
                        $scope.departments = response.data.data.data.map(function(a) {
                            a.imgurl = baseUrl + '/images/department/' + a.id;

                            return a;
                        });
                        $scope.paginator.pageTotal = response.data.data.total;
                    }
                    if (response.status == 204) {
                        $scope.departments = [];
                    }

                });
            },
            getSubcategories: function() {

                $scope.selected_sbc = []

                if ($scope.cache.subcategories === undefined) {
                    $scope.cache.subcategories = [];
                }

                var category_id = $scope.selected_category.id;
                var data = {
                    category_id: category_id
                };

                if ($scope.cache.subcategories[category_id] !== undefined) {
                    console.log('already cached');
                    $scope.subcategories = $scope.cache.subcategories[category_id];
                    return;
                }

                categoriesModel.getCategories(data).then(function successCallback(response) {
                    if (response.status == 200) {

                        $scope.cache.subcategories[category_id] = response.data.data;
                        $scope.subcategories = response.data.data;

                    }
                    if (response.status == 204) {
                        $scope.suCtegories = {};
                    }
                }, function errorCallback(response) {
                    $scope.subcategories = {};
                });
            },
            addToDepartment: function() {

                var category_id = $scope.selected_category.id;

                if ($scope.departmentCategories[category_id] == undefined) {
                    $scope.departmentCategories[category_id] = [];
                }

                $scope.departmentCategories[category_id].title = $scope.selected_category.title;
                $scope.departmentCategories[category_id].subcategories = $scope.getSelectedSubcategories();

            },
            getSelectedSubcategories: function() {

                var selected = [];

                $scope.selected_sbc.map(function(a, b) {
                    if ($scope.subcategories[b]) {
                        selected.push($scope.subcategories[b]);
                    }
                });
                //console.log(selected);
                return selected;
            },
            toggleStatus : function(department){
                departmentModel.toggleStatus(department.id,department.active).then(function succesCallback(response){
                    if(response.status == 200){
                        //update the label
                    }
                });
            },
        });
    }
]);
