myApp.controller( 'storeController', [
                        '$scope',
                        'storeModel',
                        '$location',
                        '$routeParams',
                        'productModel' ,
                        '$anchorScroll',
                        '$timeout',
                        '$controller',
                        'Upload',
                        function(
                                $scope,
                                storeModel,
                                $location,
                                $routeParams,
                                productModel,
                                $anchorScroll,
                                $timeout,
                                $controller,
                                Upload){
    $scope.address = {};
    $scope.store  = {};
    $scope.register = {};
    $scope.store.city_id = undefined;
    $scope.store.state_id = undefined;
    $scope.store.country_id = undefined;
    $scope.storeProducts = undefined;
    $scope.stores = null;
    $scope.activeStore = false;
    $scope.filters = {};
    $scope.errors = {};
    $scope.submitDisabled = false;
    $scope.errors.countryMsg = {};
    $scope.errors.storeName = {exists :false};
    $scope.errors.stateMsg = {};
    $scope.errors.cityMsg = {};
    $scope.errors.nameMsg = {};
    $scope.errors.descriptionMsg = {};
    $scope.errors.messageResponse = {};
    $scope.paginator = {};
    $scope.paginator.maxSize = 9;
    $scope.page = 1 ;
    /**
     * @todo move the location to a service
     */
    angular.extend($scope,storeModel,$location,$routeParams,productModel,$controller('gmapController',{$scope:$scope}), {
    getCountry : function(val){
      return storeModel.getCountry(val).then( function successCallback(response){
        return response.data.map(function(value){
          //return value.name;
          return { name  : value.name,  id : value.id };
        });
      });
    },

    getState : function(val){
      return storeModel.getState($scope.store.country_id, val ).then(function successCallback(response){
        return response.data.map(function(value){
          //return value.name;
          return { name  : value.name,  id : value.id };
        });
      });
    },
    getCities : function( val ){
      return storeModel.getCity($scope.store.state_id, val).then( function successCallback(response){
        return response.data.map(function(value){
          return { name  : value.name,  id : value.id };
        });
      });
    },
    onSelect  : function($item,$model,$label){
      $scope.store.state = '';
      $scope.store.state_id = '';
      $scope.store.city = '';
      $scope.store.city_id = '';
      $scope.store.country_id = $item.id;
      $scope.country  = $item;
    },
    onSelectState : function($item,$model,$label){
      $scope.store.city = '';
      $scope.store.city_id = '';
      $scope.store.state_id = $item.id;
      $scope.state  = $item;
    },
    onSelectCity : function($item , $model, $label){
      $scope.store.city_id = $item.id;
      $scope.city  = $item;
      $scope.getMapFromLocation();
      //debugger;
    },
    createStore : function(storeData){
        $scope.submitDisabled = true;
        var objStore = {
            name : $scope.store.name,
            facebook : $scope.store.facebook_store,
            twitter : $scope.store.twitter_store,
            linkedin : $scope.store.linkedin_store,
            pinterest : $scope.store.pinterest_store,
            youtube : $scope.store.youtube_store,
            whatsapp : $scope.store.whatsapp_store,
            description : $scope.store.description,
            country_id : $scope.store.country_id,
            state_id : $scope.store.state_id,
            city_id : $scope.store.city_id,
            image   : $scope.file,
        };

        storeModel.create(objStore).then(function (response){
          $scope.submitDisabled = false;
          $location.path('/user/dashboard/');

        }, function (response){

          $scope.submitDisabled = false;

          if(response.status == 409 ){
            $scope.errors.storeName = { exists : true };
            console.log("nombre de la tienda ya existe")
          }if(response.status == 400){
            //validation errors
            //debugger;
            var errors = response.data.data;
            $scope.errors = {};
            if (errors.hasOwnProperty('country_id') ){ $scope.errors.countryMsg = {'required':true} }
            if (errors.hasOwnProperty('state_id') ){ $scope.errors.stateMsg = {'required':true} }
            if (errors.hasOwnProperty('city_id') ){ $scope.errors.cityMsg = {'required':true} }
            if (errors.hasOwnProperty('name') ){ $scope.errors.nameMsg = {'required':true} }
            if (errors.hasOwnProperty('description') ){ $scope.errors.descriptionMsg = {'required':true} }

          }
        }, function (evt) {
            var progressPercentage = parseInt(100.0 * evt.loaded / evt.total);
            console.log('progress: ' + progressPercentage + '% ');
        });
    },
    update : function(form){


      $scope.store.image = $scope.store.file;

      if($scope.map){
        $scope.store.latitude  = null;
        $scope.store.longitude = null;
      }else{
        $scope.store.latitude  = $scope.map.marker.coords.latitude;
        $scope.store.longitude = $scope.map.marker.coords.longitude;
      }
      $scope.store.street = $scope.address.street;
      $scope.store._method = 'PUT';

      storeModel.update($scope.store).then( function (response){
        if(response.status == 200 ){
          $scope.errors.messageResponse = {'validationErrors': false};
          $scope.errors.messageResponse = {'success': true};
          var old = $location.hash();
          $location.hash('mainContent');
          $anchorScroll();
          $location.hash(old);
        }
        if(response.status == 204){
          $location.path('/');
        }
       }, function (response){
        if(response.status == 400 ){
          $location.path('/');
        }
        if(response.status == 412){
          var errors = response.data.data;
          $scope.errors = {};
          $scope.errors.messageResponse = {'validationErrors': true};
          if (errors.hasOwnProperty('country_id') ){ $scope.errors.countryMsg = {'required':true} }
          if (errors.hasOwnProperty('state_id') ){ $scope.errors.stateMsg = {'required':true} }
          if (errors.hasOwnProperty('city_id') ){ $scope.errors.cityMsg = {'required':true} }
          if (errors.hasOwnProperty('name') ){ $scope.errors.nameMsg = {'required':true} }
          if (errors.hasOwnProperty('description') ){ $scope.errors.descriptionMsg = {'required':true} }
          if (errors.hasOwnProperty('utility') ){ $scope.errors.utility = {'format':true} }
          if (errors.hasOwnProperty('iva') ){ $scope.errors.iva = {'format':true} }
          var old = $location.hash();
          $location.hash('mainContent');
          $anchorScroll();
          $location.hash(old);
        }
       });
    },
    initStore : function(){
     storeModel.getStore($routeParams.store_id).then(
       function successCallback(response){
         if(response.status  == 200){
          $scope.store = response.data.data;
          $scope.store.storeImgUrl = baseUrl +'/images/store/'  +response.data.data.id;
          $scope.initStorePublications($scope.store.id);
         }else if(response.status == 204){
           window.location = '/#!/tiendas';
         }
       },
       function errorCallback(response){
         //redirect to 404 page
         //$location.redirect('/notfound');
     });
   },
   initUserStore : function(){
     //edit the store
     storeModel.getStoreFromSession($routeParams.store_id).then(function successCallback(response){

      if(response.status == 200){

        $scope.activeStore = true;
        $scope.open_acordion = false;

       $scope.store = response.data.data;
       $scope.state = $scope.store.state;
       $scope.city = $scope.store.city;

       $scope.address.street = ( $scope.store.street == 'null' ) ? '' : $scope.store.street;

       var center = {};
       if(!$scope.store.latitude && !$scope.store.longitude){

         $scope.getMapFromLocation();

       }else{
         //wait the cicle to end
         $timeout(function(){
          center = { latitude: $scope.store.latitude, longitude:$scope.store.longitude }
          $scope.initMap(center,16,{
            coords : Object.create(center),
          });
         });

       }
      }
     }, function errorCallback(response){
      console.log("error?");
     });
   },
   openAcordion : function(){
       $scope.open_acordion = !$scope.open_acordion;
   },
   getStores : function(){
    //debugger;
    $scope.filters  = $routeParams ;
    $scope.filters.limit = 25;
    $scope.filters.page  = $scope.page === undefined  ? 1 : $scope.page;

     storeModel.find($scope.filters).then(function successCallback(response){
       $scope.stores = response.data.data.data.map(function(a){
         a.imgurl = baseUrl + '/images/store/'+a.id;
         //debugger;
         //a.parsedName = a.name.replace(/\s/g,'-').toLowerCase();
         return a;
       });
       $scope.paginator.pageTotal = response.data.data.total;

     });
   },
   initStorePublications : function(store_id){
     var _id = (store_id == null || store_id == undefined) ? $routeParams.store_id : store_id;
     var params = {'store_id' : _id , limit : 9 };
     productModel.find(params).then(function successCallback(response){
       if(response.status == 200){
          $scope.storeProducts = response.data.data.data.map(function(val){
          val.productImgUrl = baseUrl+'/images/publication/'+val.id;
          console.log(val);
          return val;
        });
       }else if(response.status == 204){
         $scope.storeProducts = [];
       }

       console.log(response);
     }, function errorCallback(response){
       $scope.storeProducts = [];
     });
   },
   test : function(a){
    console.log(a);
   }
  });
}]);
