myApp.controller('manageBrandsController', [
    '$scope',
    '$routeParams',
    'brandsModel',
    function (
            $scope,
            $routeParams,
            brandsModel) {

        $scope.brands = [];
        $scope.brand_status = {};
        $scope.edit_brand = {};

        angular.extend($scope,brandsModel,{
            init : function(){
                    $scope.reloadBrands();
            },
            setBrand : function(){
                $scope.edit_brand = $scope.selected_brand;
                $scope.edit_brand_name = $scope.selected_brand.name;
            },
            editBrand : function(){
                var data = {
                    name : $scope.edit_brand_name,
                }
                brandsModel.put($scope.edit_brand.id,data).then(function successCallback(response){
                    if(response.status == 200 ){
                        $scope.reloadBrands();
                        //$scope.edit_brand_name = '';
                        $scope.brand_filter = '';
                        $scope.selected_brand = {};
                        $scope.brand_status.success = false;
                        $scope.brand_status.exists  = false;
                        $scope.brand_status.updated = true;

                    }
                },function errorCallback(response){

                });
            },
            addBrand : function(){
                var data = {
                    name : $scope.brand_name,
                };
                brandsModel.post(data).then(function successCallback(response){
                    if (response.status== 201){
                        $scope.brand_status.success = true;
                        $scope.brand_status.exists  = false;
                        $scope.brand_status.updated = false;
                        $scope.brand_filter = '';
                        $scope.brand_name = '';
                        $scope.reloadBrands();

                    }else if ( response.status == 200 ){
                        $scope.brand_status.success = false;
                        $scope.brand_status.exists  = true;
                        $scope.brand_status.updated = false;
                    }
                },function errorCallback(response){

                });
            },
            reloadBrands : function(){
                $scope.brands = [];
                brandsModel.get().then(function successCallback(response){
                    if (response.status == 200 ){
                        //$scope.brands = response.data.data;

                        if ( typeof response.data.data == 'object'){
                            response.data.data.forEach(function(a){
                                a.name = a.name.toLowerCase();
                                $scope.brands.push(a);
                            })
                        }

                    }
                });
            },
        });
    }
]);
