myApp.controller( 'dashboardController', [
                                        '$scope',
                                        '$location',
                                        'storeModel',
                                        'userModel', function($scope,$location,storeModel,userModel){
        $scope.user = {};
        $scope.user.hasStore = false;

        $scope.store = {};

        angular.extend($scope,$location, storeModel,userModel,{

        init : function(){
            userModel.userHasStore().then(function successCallback(response){
                console.log(response);
                if(response.status == 200){
                    $scope.user.hasStore = response.data.data.has_store == "1" ? true: false;
                    $scope.user.name = userModel.getSavedUser();
                    $scope.user.id = response.data.data.user_id;
                    $scope.store.name = response.data.data.store_name;
                    $scope.user.type  = response.data.data.type;
                    $scope.user.store_status = response.data.data.store_status;

                }
            }, function errorCallback(response){

            });
        },
        checkHasStore : function(){
            var boolHasStore = userModel.userHasStore().then(function successCallback(response){

            if(response.status == 204){
                $location.path('/user/dashboard/crear-tienda');
            }else{
                $location.path('/user/dashboard/tienda');
            }

            },function errorCallback(response){
            //debugger;
            //$location.path('/user/dashboard/crear-tienda');
            //@todo impement message for error
            $scope.showStore = false;
         });
    },

  });

}]);
