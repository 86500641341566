myApp.controller('userController', [
    '$http',
    '$scope',
    '$location',
    'userModel',
    'ordersModel',
    'Upload',
    '$auth',
    'locationModel',
    '$routeParams',
    'localStorageService',
    function (
            $http,
            $scope,
            $location,
            userModel,
            ordersModel,
            Upload,
            $auth,
            locationModel,
            $routeParams,
            localStorageService) {
        $scope.pendingOrders = true;
        $scope.errors = {};
        $scope.errors.passwords = {};
        $scope.errors.email = {};
        $scope.errors.recaptcha = {};
        $scope.errors.name = {};
        $scope.errors.unkwon = {};
        $scope.user = {};
        $scope.user.name;
        $scope.user.id;
        $scope.user.type;
        $scope.register  = {};
        $scope.accountMessages = {};
        $scope.user.profile_image = null;
        $scope.loading = false;
        $scope.recaptcha = {};
        $scope.show_menu = false;
        $scope.recaptcha.key = (location.hostname == 'webstores.vendobara.com') ? '6LfqBC0UAAAAALqks72okAUCpEz1NYHK-stZRtv2' : '6LeBGh8UAAAAALHg-HniVZoSM_pITw_iL6oPhZ2M';
        angular.extend($scope, userModel,ordersModel, $routeParams, {

            getCountry : function(val){
                return locationModel.getCountry(val).then( function successCallback(response){
                    return response.data.map(function(value){
                    //return value.name;
                    return { name  : value.name,  id : value.id };
                    });
                });
            },

            getState : function(val){
                return locationModel.getState($scope.register.country_id, val ).then(function successCallback(response){
                    return response.data.map(function(value){
                    //return value.name;
                    return { name  : value.name,  id : value.id };
                    });
                });
            },

            getCities : function( val ){
                return locationModel.getCity($scope.register.state_id, val).then( function successCallback(response){
                    return response.data.map(function(value){
                        return { name  : value.name,  id : value.id };
                    });
                });
            },
            onSelect  : function($item,$model,$label){
                $scope.register.state = '';
                $scope.register.state_id = '';
                $scope.register.city = '';
                $scope.register.city_id = '';
                $scope.register.country_id = $item.id;
            },
            onSelectState : function($item,$model,$label){
                $scope.register.city = '';
                $scope.register.city_id = '';
                $scope.state = $item;
                $scope.register.state_id = $item.id;
            },

            onSelectCity : function($item , $model, $label){
                $scope.register.city_id = $item.id;
                $scope.city = $item;
            },

            doLogin: function (loginForm) {

                if($routeParams.from == 'cart'){
                    localStorageService.set('cart-redirect',1);
                }

                $scope.loading = true;
                var userObj = {
                    email: $scope.login.email,
                    password: $scope.login.password
                }

                $auth.login(userObj)
                        .then(function (response) {
                            userModel.saveUser(response.data.name,response.data.id,response.data.type);
                            $http({
                                headers : {"Content-Type":"application/json" },
                                method : "GET",
                                url :baseUrl+"/orders/pending/"+response.data.id,
                            }).then(function(response){
                                if(response.status == 200){
                                    $scope.pendingOrders = true
                                }else{
                                    $scope.pendingOrders = false
                                }
                                console.log($scope.pendingOrders)
                            });
                            var to = parseInt(localStorageService.get('cart-redirect'));
                            localStorageService.remove('cart-redirect');
                            if(to == 1){
                                $location.search({});
                                $location.path('/checkout/1');
                                return;
                            }else{
                                $location.path('/');
                            }

                        })
                        .catch(function (response) {

                            if (response.status == 403) {
                                $scope.messageResponse = {'inactive': true};
                            } else {
                                $scope.messageResponse = {'fail': true};
                            }
                            $scope.loading = false;
                        });


            },
            removeDisplayedErrors : function(){
                $scope.errors.email = {required :false };
                $scope.errors.email = {duplicate :false };
                $scope.errors.recaptcha = {required :false };
                $scope.errors.passwords = {password_match :false };
                $scope.errors.passwords = {password_match :false };
            },
            registerUser: function (registerForm) {
                $scope.register.processing = true;
                $scope.removeDisplayedErrors();

                if ($scope.register.password != $scope.register.password_confirmation) {
                    $scope.errors.passwords = {'password_match': true};
                    $scope.register.processing = false;

                } else {
                    $http({
                        headers: {'Content-Type': 'application/json'},
                        url: baseUrl + '/user/register',
                        method: 'POST',
                        data: {
                            name: $scope.register.name,
                            email: $scope.register.email,
                            password: $scope.register.password,
                            password_confirmation: $scope.register.password_confirmation,
                            _g_recaptcha : $scope.register.recaptcha,
                            /*city_id : $scope.register.city_id,
                            state_id : $scope.register.state_id,
                            country_id : $scope.register.country_id,
                            */
                        }
                    }).then(
                            function successCallback(response) {
                                $scope.messageResponse = {'email': false};
                                switch (response.status) {
                                    case 200:
                                        $location.path('/bienvenido');
                                        break;
                                }

                            },
                            function errorCallback(response) {
                                $scope.register.processing = false;
                                if (response.status == 412) {
                                    if (response.data.data.errors.hasOwnProperty('email')) {
                                        $scope.errors.email = {'duplicate': true};
                                    }
                                    if(response.data.data.errors.hasOwnProperty('_g_recaptcha')){
                                        $scope.errors.recaptcha = {'required': true};
                                    }
                                }else if(response.status == 500 ) {
                                    $scope.errors.unkwon = {'BF_error' : true };
                                }
                            }
                    );
                }
            },
            doLogout: function () {
                $auth.logout();
                $location.path('/');
            },
            recoveryPassword: function () {
                $scope.recovery.sending = true;
                var userObj = {
                    email: $scope.recovery.email,
                }

                userModel.recoveryPassword(userObj).then(function successCallback(response) {
                    $scope.recovery.sending = false;
                    if(response.status == 200){
                        $scope.hide_recovery = true;
                        $scope.recovery = { success : true };
                    }

                }, function errorCallback(response) {
                    //any fucking error here
                    $scope.recovery.sending = false;
                    $scope.recovery = { error : true };
                });
            },
            getAuthStatus: function () {
                $scope.user.name = userModel.getSavedUser();
                $scope.user.type = userModel.getSavedUserType();
                return $auth.isAuthenticated();
            },
            pendingPayments: function(){
                ordersModel.pendingOrders(userModel.getSavedUserId()).then(function(response){
                    if(response.status == 200){
                        $scope.pendingOrders = true
                    }else{
                        $scope.pendingOrders = false
                    }
                });
                return $scope.pendingOrders;
            },
            redirectLogin: function () {
                $location.path('/login');
            },
            initProfile: function () {
                userModel.getUser().then(function successCallback(response) {
                    console.log(response.data.data);
                    if (response.status == 200) {
                        $scope.user = response.data.data;
                        if(!$scope.user.profile_image){
                            $scope.file=true;
                            $scope.user.profile_image_url=null;
                        }
                        else
                            $scope.user.profile_image_url = baseUrl + "/images/profile/"+$scope.user.id+"/"+$scope.user.profile_image;
                    }
                });
            },
            submitAvatar: function () {

                if ($scope.form.file.$valid && $scope.file) {
                    $scope.upload($scope.file);
                }
            },
            upload: function (file) {

                Upload.upload({
                 url: baseUrl + '/images/profile',
                    data: { image: file }
                 }).then(function (resp) {
                     if(resp.status == 200){
                        $scope.accountMessages = {success:true};
                        userModel.getUser().then(function successCallback(response) {
                            console.log("second",response.data.data);
                            if (response.status == 200) {
                                user = response.data.data;
                                if(user.profile_image){
                                    $scope.user.profile_image_url = baseUrl + "/images/profile/"+$scope.user.id+"/"+user.profile_image;   
                                }
                                    
                            }
                        });
                     }

                 }, function (resp) {
                    console.log('Error status: ' + resp.status);
                 }, function (evt) {
                    var progressPercentage = parseInt(100.0 * evt.loaded / evt.total);
                    console.log('progress: ' + progressPercentage + '%');
                 });
            },
            update: function () {

                if(!$scope.form.$invalid && $scope.user.password_confirmation==$scope.user.password){
                    userModel.update($scope.user).then(function successCallback(response) {
                        if (response.status == 200) {
                            //show success message
                            $scope.messageResponse = {success: true};
                            $scope.accountMessages = {success:false};
                        }
                        if (response.status == 204) {
                            $scope.messageResponse = {whatError: true};
                        }
                    }, function errorCallback(response) {
                        if (response.status == 412) {
                            $scope.messageResponse = {validationErrors: true};
                        }
                    });
                }
            },
            showMenu : function(){

              if( $scope.show_menu == undefined || $scope.show_menu == null ) {
                $scope.show_menu = true;
                return;
              }

              $scope.show_menu =  !$scope.show_menu;
              console.log($scope.show_menu);
              return;
            },
            getType: function(){
                return localStorageService.get("user_type");
            }

        });
    }]);
