myApp.controller( 'storePublicationController', [
                        '$scope',
                        'userModel',
                        '$location',
                        '$routeParams',
                        '$auth',
                        'productModel' ,
                        'categoriesModel',
                        function(
                                $scope,
                                userModel,
                                $location,
                                $routeParams,
                                $auth,
                                productModel,
                                categoriesModel){

  $scope.store  = {};
  $scope.storeProducts = [];
  $scope.stores = null;
  $scope.filters = {};
  $scope.errors = {};
  $scope.maxSize = 9;
  $scope.page = 1;
  $scope.nextPage = 2;
  $scope.pageTotal = null;
  $scope.statuses = [
      { id : "1" , label : "Activo"  },
      { id : "0" , label : "Inactivo"},
      { id : "2" , label : "Pendiente"},
      { id : "3" , label : "Expirado"},

  ];
  $scope.filters.status = 'all';
  $scope.user = {};
  $scope.review_pending_ads_url = '';
  $scope.success = false

  angular.extend($scope,userModel,$location,$routeParams,productModel,categoriesModel,$auth, {

    init : function(){
        //first the client store info
        userModel.userHasStore().then(function successCallback(response){
            if(response.status == 200){
                $scope.store.id = response.data.data.store_id;
                $scope.store.name = response.data.data.store_name;
                $scope.filters.store_id = response.data.data.store_id;
                $scope.getStorePublications();
                $scope.loadCategories();
            }
        });

    },
    initPublicationAds : function(){
        userModel.getUser().then(function successCallback(response){
            $scope.user = response.data.data;
            $scope.getUserPublicationsAds();
            $scope.loadCategories();
        },function errorCallback(response){

        });
    },
    initAdminProducts : function(){
        $scope.getProductsOnly();
    },
    loadCategories : function(){
        categoriesModel.getCategories().then(function successCallback(response){
                $scope.categories = response.data.data;
        });
    },
    getProductsOnly : function(){
        $scope.filters.limit = 9;
        $scope.filters.page = $scope.page === undefined ? 1 : $scope.page;
        $scope.filters.store_products = 1; //get the user publicationAds
        $scope.filters.user_id = userModel.getSavedUserId();
        productModel.find($scope.filters).then(function successCallback(response){
            if ( response.status == 200 ) {
                $scope.storeProducts = response.data.data.data;
                $scope.storeProducts.map(function(a){
                    a.imgurl = baseUrl + '/images/publication/' + a.id + '/main.jpeg';
                    console.log(a.imgurl);
                    return a;
                });
                $scope.pageTotal = response.data.data.total;
            }else{
                $scope.storeProducts = [];
            }

        }, function errorCallback(){
            $scope.storeProducts = [];
        });
    },
    getUserPublicationsAds : function(){

        $scope.filters.limit = 9;
        $scope.filters.page = $scope.page === undefined ? 1 : $scope.page;
        $scope.filters.category_id = ($scope.category === undefined) ? null : $scope.category.id;
        $scope.filters.publication_ad = 1; //get the user publicationAds
        $scope.filters.user_id = userModel.getSavedUserId();

        productModel.find($scope.filters).then(function successCallback(response){
            if ( response.status == 200 ) {
                $scope.storeProducts = response.data.data.data;
                $scope.storeProducts.map(function(a){
                    a.imgurl = baseUrl + '/images/publication/' + a.id + '/main.jpeg';
                    return a;
                });
                $scope.pageTotal = response.data.data.total;

                if ( $scope.filters.status == 2 && $scope.storeProducts.length > 0  ){
                    var first_ad_id = $scope.storeProducts[0].id;

                    $scope.review_pending_ads_url =
                    site_url+'/user/dashboard/anuncios/editar/'+first_ad_id+'?review_pending_ads=1';
                }
            }else if (response.status == 204){
                $scope.storeProducts = [];
                $scope.pageTotal = 0;
            }

        }, function errorCallback(){
            $scope.storeProducts = [];
        });
    },
    getStorePublications : function(){
        $scope.filters.limit = 9;
        $scope.filters.page = $scope.page === undefined ? 1 : $scope.page;
        $scope.filters.category_id = ($scope.category === undefined) ? null : $scope.category.id;
        $scope.filters.older = 'desc';

        productModel.find($scope.filters).then(function successCallback(response){
            if ( response.status == 200 ) {
                $scope.storeProducts = response.data.data.data;
                $scope.storeProducts.map(function(a){
                    a.imgurl = baseUrl + '/images/publication/'+a.id+'/main.jpeg';
                    return a;
                });
                $scope.pageTotal = response.data.data.total;
            }else{
                $scope.storeProducts = [];
            }

        }, function errorCallback(){
            $scope.storeProducts = [];
        });
    },
    filter : function(){
        $scope.filters.page = 1;
        $scope.page = 1;
        $scope.getStorePublications();
    },
    filterAds : function(){
        $scope.filters.page = 1;
        $scope.page = 1;
        $scope.getUserPublicationsAds();
    },
    updatePrice: function(){
        $scope.errors.updateprice = false
        $scope.success = false
        productModel.updatePrice($scope.filters).then(function successCallback(response){
            if ( response.status == 200 ) {
                $scope.success = true
                setTimeout(()=> { $scope.success = false }, 1000)
                $scope.filters.page = 1;
                $scope.page = 1;
                $scope.getStorePublications();
            }

        }, function errorCallback(error){
            if(error.status != 500){
                $scope.errors.updateprice = true
                setTimeout(()=> { $scope.errors.updateprice = false }, 1000)
            }
        });
    },
    deactivatePublication: function(){

    },
    toggleStatus : function(pub){
        console.log(pub);
        productModel.toggleStatus(pub.id,pub.switch).then(function succesCallback(response){
            if(response.status == 200){
                //update the label
            }
        });
    },

    toggleStatusProduct : function(pub){
        console.log(pub);
        productModel.toggleStatus(pub.id,pub.status).then(function succesCallback(response){
            if(response.status == 200){
                //update the label
            }
        });
    },

  });

}]);
