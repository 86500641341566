myApp.controller('gmapController',["$scope","uiGmapGoogleMapApi", function($scope,uiGmapGoogleMapApi){
    $scope.map = {};

    $scope.initMap =  function(center,zoom,marker){
        
        var _zoom = zoom || 11;
        var _marker = marker || {};

        $scope.map = {
            center : center,
            zoom : _zoom,
            marker : _marker,
            events :{
                click : function(map,eventName,originalEventArgs){            
                    var e = originalEventArgs[0];            
                    $scope.map.marker = {              
                    coords : { latitude : e.latLng.lat(), longitude : e.latLng.lng()  }  
                    };                       
                    $scope.$apply();
                },
            }
        };
    },
    $scope.getMapFromLocation = function(){

        if($scope.state == null && $scope.city == null && $scope.city == null ){
            //if we dont have any location so we set this as default
            var _state_name = 'Sonora';
            var _city_name = 'Hermosillo';
            var _street = '';
        }else{
            var _state_name  = ($scope.state.name) ? $scope.state.name : $scope.state;
            var _city_name  = ($scope.city.name) ? $scope.city.name : $scope.city;
            var _street = ($scope.address.street) ? $scope.address.street+",+" : "";
        }
        
        var _address = _street+_city_name+"+"+_state_name;
        console.log(_address);
        uiGmapGoogleMapApi.then(function(maps){
            geocoder = new maps.Geocoder();
            geocoder.geocode({address: _address},function(results,status){
            
            var _latitude = results[0].geometry.location.lat();
            var _longitude = results[0].geometry.location.lng();
            console.log("lat: " + _latitude + ", long: " + _longitude);
            center = { latitude : _latitude, longitude : _longitude };
            var zoom  = ($scope.address.street) ? 16 : 11;
            $scope.initMap(center,zoom);
            $scope.$apply();
            });
        });
    }
}]);