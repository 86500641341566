myApp.controller('cartController', function ($scope,cartModel,$location,
    wishListService,localStorageService,CTModel,$anchorScroll,$rootScope,$timeout,$auth) {

    $scope.billing_info = {};
    $scope.lastUrlSearch = '';
    $scope.token = '';
    $scope.cartErrors = false;
    $scope.external_codes = [];
    $scope.errors = {};
    $scope.no_stock_items = [];

    $scope.$on('reloadCart', function(event, args){
        $scope.cartList = args;
        $scope.buildCartList();
    });

    angular.extend($scope,cartModel,$location,wishListService,
        localStorageService,CTModel,$anchorScroll,$timeout,$auth,{

        initCart : function () {
            $scope.buildCartList();
        },

        saveForLater : function(id,index){
            
            wishListService.add(id).then(function successCallback(response){
                if(response.status == 201){
                    $scope.wishListMessages = {added:true};
                }

                if(response.status == 200){
                    $scope.wishListMessages = {repeated:true};
                }
                $scope.removeItem(id);

             },function errorCallback(response){
                $scope.wishListMessages = {error:true};
             });
        },

        buildCartList : function(){
            $scope.lastUrlSearch = localStorageService.get('last_search_url');

            if($scope.lastUrlSearch == ''){
                $scope.lastUrlSearch = '/publicaciones';
            }
            var cartList = []
            var tmpCartList = cartModel.getTmpCartList();
            cartModel.getCartList().then(
                response => {
                    if(response.data){
                        cartList = response.data
                    }
                    if(tmpCartList.length > 0 && cartList.length > 0 ){
        
                        //merge both into the cart list
                        cartList = cartList.concat(tmpCartList);
                    }
        
                    var storeProductlistTmp = [];
                    var storeProductlist = [];
                    var productIds = [];
        
        
                    $scope.errors = { notEnoughItemsForCart : false };
                    if(cartList.length > 0 ){
                        console.log(cartList)
                        cartList.map(function(a,b){
        
                            if( a.has_empty_stock ){
                                $scope.errors = { notEnoughItemsForCart : true };
                            }
        
                            if(storeProductlistTmp[parseInt(a.store_id)] === undefined){
                                //we ignore repeated products in the list
                                if( productIds[a.id] == undefined ){
                                    productIds[a.id]  = 1 ;
                                    storeProductlistTmp[parseInt(a.store_id)] = { id : parseInt(a.store_id) , name: a.store_name, amount : 0 , products : [] };
                                    storeProductlistTmp[parseInt(a.store_id)].amount += (a.price * a.quantity);
                                    storeProductlistTmp[parseInt(a.store_id)].products.push(a);
                                }
                            }else{
        
                                if( productIds[a.id] == undefined ){
                                    productIds[a.id]  = 1 ;
                                    storeProductlistTmp[parseInt(a.store_id)].products.push(a);
                                    storeProductlistTmp[parseInt(a.store_id)].amount += (a.price * a.quantity );
                                }
                            }
                        });
        
                        // the cart is merged so we can remove the temp list is we are on the cart page
                        if( $location.$$url == '/cart' && tmpCartList.length > 0 ){
                            cartModel.cleanTmpCartList();
                            cartModel.setCartList(cartList);
                        }
        
                    }else{
                        $scope.errors = {notElementsInCart : true };
                    }
                    //clean list for ngrepeat
                    if(storeProductlistTmp.length > 0 ){
                        storeProductlistTmp.forEach(function(a,b){
                            storeProductlist.push(a);
                        });
        
                        storeProductlistTmp = null;
                    }
        
                    $scope.cartParsedList = storeProductlist;
                    $scope.cartList = cartList;
                }
            );

        },

        paystore : function(store_id){

            var store_products_tmp  = [];
            var store_products_tmp_not = [];

            $scope.cartList.forEach(function(a,b){
                if(a.store_id == store_id){
                    store_products_tmp.push(a);
                }else{
                    store_products_tmp_not.push(a);
                }
            });

            $scope.cartList = store_products_tmp;
            //save into the cart storage
            cartModel.setCartList($scope.cartList);
            cartModel.setTmpCartList(store_products_tmp_not);

            $scope.checkoutStep1();
        },

        removeItem : function (id) {

            var index;
            $scope.cartList.map(function(a,b){
                if(id == a.id){
                    index = b;
                }
            });
            $scope.cartList.splice(index, 1);
            cartModel.setCartList($scope.cartList);
            $scope.buildCartList();
            $rootScope.$broadcast('reloadCartMenu');
        },

        updateQuantity : function (id, method ,quantity) {

            if (quantity == 0) {
                $scope.removeItem(index);
            } else {
                $scope.cartList.map(function(a){
                    if(a.id == id){
                      if( method == 'add'){
                        a.quantity = a.quantity + 1;
                      }else if ( method == 'remove')
                        a.quantity = a.quantity - 1;
                        if(a.quantity <= 0 ){
                          a.quantity = 1;
                        }
                    }
                });
                //$scope.cartList[index].quantity = quantity;
                cartModel.setCartList($scope.cartList);
            }
            $scope.buildCartList();
        },

        getTotal : function(){
            var total = 0;
            angular.forEach($scope.cartList,function(value, key){
                total += value.quantity*value.price;
            });
            return total;
        },

        checkoutStep1 : function(){

            $scope.errors = { notElementsInCart : false , notLogedIn : false };

            if(!$auth.isAuthenticated()){
                $scope.errors = { notLogedIn : true };
                $location.search({'from':'cart'});
                $location.path('/login');
                return;
            }
            if( !( $scope.cartList == null  || $scope.cartList.length == 0 ) ){

                //check availability in the store
                $scope.external_codes = [];
                $scope.cartErrors = false;
                var products_stream = '';

                var flag = false;
                $scope.cartList.forEach(function(a){
                    if(a.external_key !== null && a.external_key.length > 0 ){
                        if(!flag){
                            products_stream = a.external_key;
                            flag = true;
                        }else{
                            products_stream += "+"+a.external_key;
                        }
                    }
                });

                if(products_stream.length > 0 ){

                    CTModel.getProductAvailability(products_stream).then(function successCallback(response){

                        if(response.status == 200 ){
                            var cart_items = response.data.data.cart_items;

                            if(response.data.data.has_empty_stock){
                                $scope.cartErrors = true;
                                $scope.errors = { notEnoughItemsForCart : true };
                            }

                            $scope.no_stock_items = [];

                            cart_items.forEach(function(ci){
                                $scope.cartList.map(function(cl){
                                    if( ci.external_key == cl.external_key ){
                                        if( ci.total < cl.quantity  ){
                                            $scope.no_stock_items[parseInt(ci.id)] = true;
                                            cl.has_empty_stock = true;

                                            $scope.cartErrors = true;
                                            $scope.errors = { notEnoughItemsForCart : true };
                                        }
                                    }
                                });
                            });

                            if(!$scope.cartErrors){
                                $location.path('/checkout/1');
                            }else{
                              $timeout(function () {
                                var old = $location.hash()
                                $location.hash('cartlist')
                                $anchorScroll();
                                $location.hash(old);
                              });

                            }

                        }
                    },function errorCallback(response){
                        console.log(response);
                    });
                }else{
                    if(!$scope.cartErrors){
                        $location.search({});
                        $location.path('/checkout/1');
                    }
                }


            }else{
                $scope.errors = { notElementsInCart : true };
                console.log('No hay productos para proceder');
            }
        }

    });

});
